.footer {
    $footer: &;
    padding-top: 40px;
    color: #666;

    @media #{$media-lg} {
        padding-top: 25px;
    }

    @media #{$media-xs} {
        padding-top: 0;
    }

    a {
        color: $blue-dianne;

        &:hover {
            color: $sherpa-blue;
        }
    }

    p {
        margin: 0;
        line-height: 1.2em;
    }

    &__contact-title {
        line-height: 3rem;
    }

    &__trustmate {
        background-image: linear-gradient($body-bg, transparent),
            $background-pattern;
        background-image: linear-gradient($body-bg, transparent),
            image-set(
                $background-pattern-avif type('image/avif'),
                $background-pattern type('image/jpeg')
        );
    }

    &__mega-links {
        &-wrapper {
            display: flex;
            align-items: center;
            background-color: #f5f5f5;
            flex-direction: column;
            padding: 30px 0;

            .container {
                @media (min-width: 1560px) {
                    width: 1560px;
                }
            }
        }

        &-content {
            display: grid;
            grid-template-columns: repeat(6, minmax(0, 1fr));
            gap: 64px;

            > div {
                display: none;
                margin: 20px 0;
                height: fit-content;
                width: 100%;
                position: relative;
                overflow: hidden;

                p {
                    font-size: 14px;
                    font-weight: $font-weight-light;
                    line-height: 18px;
                    margin-bottom: 12px;

                    a {
                        padding: 2px 0px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &::after {
                            content: '';
                            position: absolute;
                            right: 0;
                            width: 30px;
                            height: 100%;
                            background: linear-gradient(
                                to right,
                                transparent,
                                #f5f5f5
                            );
                        }
                    }

                    &.thead {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 28px;
                        color: $blue-dianne;
                        min-height: 56px;

                        @media #{$media-to-sm} {
                            min-height: 28px;
                        }

                        a {
                            white-space: normal;

                            &::after {
                                background: none;
                            }
                        }
                    }
                }
            }

            .displayed {
                display: block;
                overflow: hidden;
                width: 100%;
            }

            a {
                &:active,
                &:hover {
                    text-decoration: underline;
                }
            }

            //max width 1199px
            @media #{$media-to-md} {
                > div {
                    margin: 30px 0;
                }

                p {
                    text-align: left;
                }
            }

            @media #{$media-to-sm} {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin: 0 1rem;
                width: 100%;
                gap: 1rem;

                > div {
                    margin: 20px 0;
                    width: 100%;

                    p {
                        padding-right: 0 !important;
                        font-size: 10px;
                        width: 100% !important;
                        line-height: 12px;

                        &.thead {
                            font-size: 12px;
                            width: 100%;
                            line-height: 16px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        &-button {
            display: flex;
            width: 100%;
            justify-content: center;
        }
        &-rollout {
            display: flex;
            justify-content: center;
            min-width: 180px;
            margin-top: 40px;
            height: 40px;
            text-align: center;
            text-transform: none;
            border: 1px solid $sherpa-blue;
            border-radius: 32px;
            background-color: $btn-success-color;
            cursor: pointer;

            &--text {
                line-height: 40px;
                font-size: 16px;
                font-weight: 600;
                color: $blue-dianne;
            }

            .icon-arrow-indicator {
                margin-left: 10px;
                filter: $svg-main-green;
            }

            &:hover {
                background-color: $dark-green;
                border-color: $dark-green;

                .footer__mega-links-rollout--text {
                    color: $btn-success-color;
                }

                .icon-arrow-indicator {
                    filter: $svg-white;
                }
            }
        }
    }

    &__article {
        position: relative;
        margin-top: 30px;
        font-style: normal;
        color: $blue-dianne;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        max-height: 300px;
        overflow: hidden;
        scroll-margin-top: 80px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            background: linear-gradient(
                180deg,
                rgba(255, 255, 255, 0),
                #fff 100%
            );
            left: 0;
            width: 100%;
            height: 200px;
            bottom: 0;
        }

        &.is-expanded {
            max-height: none;
            overflow: unset;

            &::after {
                display: none;
            }

            & + * .icon-arrow-indicators,
            & + * .icon-arrow-indicator,
            & + * .pharmacy-icon-arrow-indicators {
                transform: scale(1, -1);
            }
        }

        &-wrapper {
            margin-bottom: 20px;

            &:empty {
                margin-bottom: 40px;
            }

            .container {
                max-width: 1000px;
            }

            a {
                &:active,
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        h2 {
            font-size: 28px;
            font-weight: 500;
            line-height: 40px;
            margin-bottom: 25px;
            margin-top: 25px;

            @media #{$media-xs} {
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                text-align: center;
            }
        }

        p {
            margin-bottom: 16px;
            line-height: 22px;

            &:empty {
                display: inline-block;
                margin-bottom: 0px;
                line-height: 0.7em;
            }
        }

        ul,
        ol {
            margin-bottom: 16px;
        }

        li > ul,
        li > ol {
            margin-bottom: 0px;
        }

        ul > li {
            list-style-type: disc;
            margin-left: 30px;
        }
        li > ul > li {
            list-style-type: circle;
        }

        ol > li {
            list-style-type: decimal;
            margin-left: 30px;
        }

        &-read-more {
            width: 100%;
            border: none;
            background: transparent !important;
            border-radius: unset;

            .icon-arrow-indicators {
                width: 16px;
                height: 16px;
                margin-left: 5px;
                filter: $svg-main-green;
            }

            .text {
                &:hover {
                    color: $sherpa-blue;
                }
                &:active,
                &:focus {
                    color: $blue-dianne;
                }
            }

            &:hover {
                color: $sherpa-blue;
                background: transparent !important;
            }
            &:active,
            &:focus {
                color: $blue-dianne;
                background: transparent !important;
            }
        }
    }

    &__newsletter {
        $self: &;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 150px;
        padding: 54px 0;

        .container {
            display: flex;
            flex-flow: row wrap;
            background-image: $background-pattern;
            background-image: image-set(
                    $background-pattern-avif type('image/avif'),
                    $background-pattern type('image/jpeg')
            );
            box-shadow: 5px 5px 10px #c1bbb1;
            border-radius: 8px;
            max-width: 1170px;
            width: 100%;
            padding: 54px 15px;

            @media (min-width: 1560px) {
                width: 1560px;
            }

            @media #{$media-to-md} {
                padding: 32px 15px;
            }
        }

        @media #{$media-to-md} {
            flex-direction: column;
        }

        &-lead {
            display: flex;
            flex: 1 0 50%;
            flex-flow: row wrap;
            align-items: center;
            justify-content: center;
            color: $sherpa-blue;

            @media #{$media-to-md} {
                margin: 0 20px;
            }

            @media #{$media-xs} {
                flex-direction: column;
            }
        }

        &-lead-header {
            display: flex;
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 40px;
            text-align: left;
            margin: 0 20px;
            max-width: 430px;

            @media #{$media-to-md} {
                margin: 16px 0;
                font-size: 16px;
                text-align: center;
                font-weight: 600;

                p {
                    line-height: 24px;
                }
            }
        }

        &-lead-icon {
            display: flex;
            min-width: 54px;
            min-height: 57px;
            @media #{$media-to-md} {
                margin: 0 20px;
            }
            @media #{$media-xs} {
                min-width: 30px;
                min-height: 32px;
            }
        }

        #{ $self }--success & {
            &-form-wrapper {
                display: none;
            }

            &-lead {
                @media #{$media-to-sm} {
                    flex-direction: column;
                }

                @media #{$media-to-md} {
                    margin: 0;
                }
            }

            &-lead-header {
                font-size: 24px;
                max-width: 700px;
                margin-right: 30px;
                text-align: left;
                line-height: 110%;

                @media #{$media-to-md} {
                    margin: 20px 30px;
                    text-align: center;
                    font-size: 18px;
                }
            }

            &-lead-icon {
                @media #{$media-md} {
                    padding: 0;

                    img {
                        width: unset;
                        height: unset;
                    }
                }

                @media #{$media-to-sm} {
                    padding-top: 20px;

                    img {
                        width: 60px;
                        height: 60px;
                    }
                }
            }
        }

        &-form-wrapper {
            display: flex;
            flex: 1 0 50%;
            margin: 30px 0 0;
            justify-content: center;

            @media #{$media-to-md} {
                width: 100%;
                margin: 8px 20px 0;
                padding: 0 30px;
                justify-content: center;
            }

            @media #{$media-xs-l} {
                margin: 8px 0 32px;
                padding: 0;
            }

            .g-recaptcha-legal {
                color: $blue-dianne;
                margin-left: 24px;
                font-size: 10px;

                a {
                    color: $blue-dianne;
                    text-decoration: underline;
                }
            }
        }

        &-agreement {
            margin: 10px 0;

            a {
                color: #000;
                text-decoration: underline;

                &:hover,
                &:active {
                    color: #000;
                    text-decoration: none;
                }
            }

            input[type='checkbox'] {
                position: absolute;
                top: 5px;
                left: 5px;
                height: 10px;
                width: 10px;
                display: inline-block;

                &:checked + .checkmark {
                    border: 1px solid $sherpa-blue;
                }

                &:checked ~ .agreement-description > p {
                    color: #000;
                }

                &:checked + .checkmark::before {
                    opacity: 1;
                }
            }

            .checkmark {
                position: absolute;
                top: -2px;
                left: 0;
                height: 20px;
                width: 20px;
                border: 1px solid $blue-dianne;
                background: #fff;
                vertical-align: middle;
                cursor: pointer;
                border-radius: 4px;

                &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 65%;
                    background-image: url('data:image/svg+xml,<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 1.19958L4.5 8.19958L1 5.19958" stroke="%23007F32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                }
            }

            em {
                color: $red;
                margin-right: 5px;
            }

            .agreement-description {
                margin: 20px 0;
            }
            .agreement-expandable {
                position: relative;
                padding-left: 25px;
                display: table-cell;
                vertical-align: middle;
            }

            .validation-advice {
                color: red;
            }
        }

        &-form-group {
            display: flex;
            align-items: stretch;
            border-radius: 24px;

            label {
                flex: 1;
            }

            button {
                height: 56px;
            }

            .validation-advice {
                margin-left: 25px;
                color: red;
            }
        }

        &-input--text {
            border-radius: 24px 0 0 24px;
            border: none;
            box-shadow: 0px 30px 40px 0px #cfc8bc1a;
            padding-left: 23px;
            height: 56px;
            min-width: 350px;
            font-style: normal;

            @media #{$media-to-md} {
                min-width: unset;
                width: 100%;
                font-size: 12px;
            }
        }

        &-input--submit {
            min-width: 152px;
            color: #fff !important;
            border-radius: 0 24px 24px 0;
            background-color: $sherpa-blue;
            height: 56px;
            padding: 0 21px;
            border: none;
            width: 152px;

            .icon-paper-plane {
                width: 24px;
                height: 24px;
                filter: $svg-white;
            }

            @media #{$media-to-sm} {
                min-width: unset;
                padding: 0 28px;
                width: 78px;

                span:nth-child(2) {
                    display: none;
                }
            }

            &:hover,
            &:active {
                background-color: $dark-green !important;
                background: $dark-green !important;
            }
        }

        &-info {
            max-width: 600px;
            padding-top: 7px;
            padding-left: 24px;
            font-style: normal;
            font-size: 14px;
            font-weight: $font-weight-light;
            line-height: 18px;
            color: $blue-dianne;

            @media #{$media-to-md} {
                font-size: 12px;
            }
        }
    }

    &__usp {
        display: flex;
        width: 100%;
        background-color: $gray-lighter2;
        justify-content: left;
        margin-bottom: 40px;
        overflow-x: auto;
        overflow-y: unset;
        scrollbar-width: none;
        padding: 53px 0;

        @media #{$media-xs} {
            padding: 18px 0;

            .product-usp__main {
                font-weight: $font-weight-bold;
                font-size: 12px;
                line-height: 16px;
                margin-top: 10px;
            }

            .product-usp__desc {
                font-weight: $font-weight-light;
                font-size: 10px;
                line-height: 12px;
                margin-top: 5px;
            }
        }

        &::-webkit-scrollbar {
            width: 0;
            display: none;
        }

        @media #{$screen} and (min-width: 1800px) {
            justify-content: center;
        }

        .product-usp {
            display: flex;
            overflow-x: unset;
            width: auto;
            gap: 10px;
            margin: 0 auto;

            &__item {
                display: flex;
                align-items: center;
                flex: 1;

                img {
                    max-width: none;
                }
            }

            &__item:nth-child(1) {
                background: url('data:image/svg+xml,<svg width="54" height="48" viewBox="0 0 54 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.66994 42.0322H1" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/><path d="M11.7908 41.9212V23.8823" stroke="%23007F32" stroke-width="1.5" stroke-linejoin="round"/><path d="M25.0667 41.9214V26.8262H17.9961V41.9214" stroke="%23007F32" stroke-width="1.5" stroke-linejoin="round"/><path d="M27.3003 0.977051H27.3077L50.3609 21.2273L47.617 24.3336L27.3003 6.50925L6.98348 24.3336L4.2395 21.2273L27.3003 0.977051Z" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.2803 6.46466V3.56543H11.9019V10.562" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10"/><path d="M30.1846 42.0322H7.51587" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M37.2182 29.7843C38.2018 28.9929 39.3556 28.379 40.6425 28.0092C45.7901 26.5374 51.1523 29.518 52.6241 34.6656C54.0959 39.8132 51.1153 45.1753 45.9677 46.6471C40.8201 48.1189 35.4579 45.1384 33.9861 39.9907C33.2687 37.4835 33.6089 34.9245 34.7479 32.7796" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M38.1648 36.7365L41.6336 41.5365L48.0237 33.8965" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M34.5778 18.5424H31.7155V15.6802H28.195V18.5424H25.3328V22.0629H28.195V24.9252H31.7155V22.0629H34.5778V18.5424Z" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            }

            &__item:nth-child(2) {
                background: url('data:image/svg+xml,<svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.8254 41.4996C41.036 41.4996 46.8813 37.8406 46.8813 33.3269C46.8813 28.8133 41.036 25.1543 33.8254 25.1543C26.6148 25.1543 20.7695 28.8133 20.7695 33.3269C20.7695 37.8406 26.6148 41.4996 33.8254 41.4996Z" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M46.8813 37.5581C46.8813 42.0721 41.0372 45.7307 33.8254 45.7307C26.6136 45.7307 20.7695 42.0721 20.7695 37.5581" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M46.8813 33.3271V37.8148" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M20.7695 33.3271V37.8148" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M43.3621 27.6826L24.123 38.7679" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M27.3545 16.1949C29.8075 13.742 29.8075 9.76498 27.3545 7.31204C24.9016 4.8591 20.9246 4.8591 18.4717 7.31204L6.06654 19.7171C3.61361 22.1701 3.61361 26.1471 6.06655 28.6C8.51948 31.0529 12.4965 31.0529 14.9494 28.6L27.3545 16.1949Z" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M20.9984 22.2445L12.2681 13.5142" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            }

            &__item:nth-child(3) {
                background: url('data:image/svg+xml,<svg width="64" height="32" viewBox="0 0 64 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22.2921 26.4972H19.1961C17.5897 26.4972 16.2754 25.1829 16.2754 23.5765V20.3198" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M48.3891 26.4971H31.2371" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.8518 1.09375H45.0812C45.0812 1.09375 54.4202 0.670238 62.9999 17.4864V23.5762C62.9999 25.1826 61.6856 26.4969 60.0792 26.4969H57.3629" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M57.2754 26.5266C57.2754 28.9508 55.3112 30.9077 52.887 30.9077C50.4627 30.9077 48.4985 28.9435 48.4985 26.5266C48.4985 24.1097 50.4627 22.1382 52.887 22.1382C55.3112 22.1382 57.2754 24.1024 57.2754 26.5266Z" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10"/><path d="M31.1638 26.5266C31.1638 28.9508 29.1996 30.9077 26.7754 30.9077C24.3512 30.9077 22.387 28.9435 22.387 26.5266C22.387 24.1097 24.3512 22.1382 26.7754 22.1382C29.1996 22.1382 31.1638 24.1024 31.1638 26.5266Z" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10"/><path d="M45.6873 6.08105V10.6374C45.6873 10.6374 45.6873 14.2664 49.141 14.2664H60.6415" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M24.5483 5.87695H5.32983" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M19.6854 20.1152H9.97388" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M24.5485 15.3691H1" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M29.4114 10.623H10.9304" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
            }

            &__item:nth-child(4) {
                background: url('data:image/svg+xml,<svg width="54" height="48" viewBox="0 0 54 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M41.3292 46.4951C54.3556 40.9685 52.9563 23.296 52.9563 23.296C44.1829 24.3337 41.3292 19.5933 41.3292 19.5933H41.227C41.227 19.5933 38.3733 24.3416 29.5842 23.296C29.5842 23.296 28.1849 40.9685 41.227 46.4951H41.3292Z" stroke="%23007F32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M36.4631 32.3289L40.8891 35.9216L46.5572 28.5947" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10"/><path d="M41.2427 15.9065V9.68029L20.913 1.50439L1 8.83911V31.4801L19.9303 43.2172L28.4207 39.1371" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10"/><path d="M1 8.83887L19.8753 18.6892L41.1562 9.73507" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10"/><path d="M19.8989 18.7603V43.0835" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10"/><path d="M30.4332 5.41113L10.3708 13.7207" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10"/><path d="M10.3708 13.8311V22.1091" stroke="%23007F32" stroke-width="1.5" stroke-linejoin="bevel"/></svg>');
            }

            &__item:nth-child(5) {
                background: url('data:image/svg+xml,<svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M38.8489 50.5988L30.3209 44.8823" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10"/><path d="M8.9581 23.6754L3.2961 15.1708C2.92227 15.5056 2.6964 15.7237 2.6964 15.7237C0.57024 17.6162 1.04532 19.2751 1.04532 19.2751C2.47834 29.4075 13.1481 39.1582 13.9581 40.0071C15.0562 41.0274 24.5811 51.4713 34.7135 52.8965C34.7135 52.8965 36.3724 53.3716 38.2649 51.2376L38.8567 50.5912C39.5888 49.6255 42.0889 46.3934 42.1122 45.856C42.1122 45.856 42.3458 44.7968 41.1075 43.909L33.6543 38.9402C33.6543 38.9402 32.3537 37.7564 30.5157 38.761L26.0219 41.2454C26.0219 41.2454 21.5126 37.6707 19.0126 34.9526C16.3023 32.4526 12.712 27.9511 12.712 27.9511L15.1964 23.4573C16.2011 21.6193 15.0172 20.3187 15.0172 20.3187L10.0406 12.8655C9.15277 11.6272 8.08583 11.8608 8.08583 11.8608C7.53287 11.8842 6.55154 12.515 5.57802 13.2627" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10"/><path d="M14.363 11.5882C17.8989 5.30313 24.6356 1.05859 32.3614 1.05859C43.7633 1.05859 53 10.2953 53 21.6972C53 29.3451 48.8411 36.0118 42.6729 39.5787" stroke="%23007F32" stroke-width="1.5" stroke-linejoin="round"/><path d="M41.3801 31.074V27.7095C41.3801 24.1971 41.3957 23.6129 35.8505 22.8809L32.3381 26.9541L28.8256 22.8809C23.2805 23.6129 23.3038 24.1971 23.3038 27.7095V31.074" stroke="%23007F32" stroke-width="1.5" stroke-linejoin="round"/><path d="M36.4112 15.1396C36.4112 17.4059 34.5809 19.2595 32.3535 19.2595C30.1261 19.2595 28.2959 17.4059 28.2959 15.1396V13.6131C28.2959 11.3467 30.1261 9.49316 32.3535 9.49316C34.5809 9.49316 36.4112 11.3467 36.4112 13.6131V15.1396Z" stroke="%23007F32" stroke-width="1.5" stroke-miterlimit="10"/></svg>');
            }

            &__item:nth-child(1),
            &__item:nth-child(2),
            &__item:nth-child(3),
            &__item:nth-child(4),
            &__item:nth-child(5) {
                display: block;
                background-repeat: no-repeat;
                background-position: top 55% left 0;
                background-size: 52px;
            }

            &__info {
                display: inline-block;
                margin-left: 22px;
                margin-top: 5px;
                width: 230px;
                align-self: flex-start;
            }

            &__main {
                font-style: normal;
                font-weight: $font-weight-bold;
                font-size: 16px;
                line-height: 20px;
                color: $blue-dianne;

                @media #{$media-xs} {
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                }
            }

            &__maininfo {
                margin-left: 60px;
                width: 230px;

                @media #{$media-xs} {
                    width: 150px;
                }
            }

            &__desc {
                margin-top: 10px;
                font-style: normal;
                font-weight: $font-weight-light;
                font-size: 14px;
                line-height: 17px;
                color: $blue-dianne;

                @media #{$media-xs} {
                    font-size: 10px;
                    font-weight: 400;
                    line-height: 12px;
                }
            }
        }
    }

    &__main {
        .container {
            display: flex;
            justify-content: space-between;
            flex-flow: row wrap;

            &::before,
            &::after {
                display: none;
            }

            @media (min-width: 1560px) {
                width: 1560px;
            }
        }

        &-column {
            font-size: 16px;
            display: flex;
            flex: 1 1 0px;
            justify-content: center;
            min-width: 250px;

            h3 {
                font-size: 16px;
            }

            &._contact-us,
            &._contact {
                .icon-telephone-head {
                    width: 32px;
                    height: 29px;
                }

                .icon-mail-to-us {
                    width: 32px;
                    height: 22px;
                }
            }

            &._links,
            &._connect {
                @media #{$media-to-sm} {
                    margin-top: 10px;
                }

                @media #{$media-md} {
                    margin-top: 10px;
                    width: calc(33.333% - 20px);
                }

                @media #{$media-lg} {
                    padding-left: 22px;
                    padding-right: 22px;
                }
            }

            &._links {
                color: $blue-dianne;

                @media #{$media-lg} {
                    padding-top: 0;
                    padding-bottom: 10px;
                }
            }

            &._connect {
                @media #{$media-lg} {
                    width: 375px;
                }
            }
        }
    }

    &__contact {
        margin-bottom: 30px;

        @media #{$media-sm-to-md} {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;
        }

        &-main {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &-logo {
            margin-bottom: 10px;

            @media #{$media-to-sm} {
                margin: 0 auto;
            }

            img {
                width: 165px;
            }
        }

        &-address {
            margin-top: 20px;
            margin-bottom: 15px;
            padding-left: 50px;
            font-weight: $font-weight-light;
            font-size: 14px;
            line-height: 18px;
            text-align: left;

            @media #{$media-to-sm} {
                text-align: center;
                padding-left: 0;
            }

            .icon {
                @media #{$media-to-sm} {
                    min-width: 30px;
                    max-width: 35px;
                }
            }

            p {
                line-height: 18px;

                &.street {
                    margin: 15px 0;
                }

                .small {
                    font-size: 10px;
                }
            }

            .social {
                display: flex;
                gap: 16px;
                margin: 15px 0;

                @media #{$media-to-sm} {
                    justify-content: center;
                }

                a > .icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        &-phone {
            padding: 16px 26px 10px 20px;
            background-color: $gray-light;
            border-radius: 8px;
            color: $sherpa-blue;
            width: 254px;
            max-height: 150px;

            @media #{$media-sm-to-md} {
                margin-top: 20px;
                min-width: 230px;
            }

            @media #{$media-to-sm} {
                width: 100%;
                min-width: unset;
                text-align: center;
            }

            a {
                display: flex;
                align-items: center;
                margin: 12px 0;
                line-height: 1em;

                @media #{$media-to-sm} {
                    justify-content: center;
                }

                &:hover {
                    color: $dark-green;
                }
            }

            .icon {
                margin-right: 15px;
            }

            p {
                font-size: 14px;
                font-weight: $font-weight-light;
                white-space: nowrap;
            }
        }

        &-title {
            display: block;
            width: 100%;
            font-size: 14px;
            font-weight: $font-weight-bold;
            color: $blue-dianne;
        }
    }

    &__links {
        padding-top: 20px;
        padding-bottom: 20px;
        min-width: 170px;
        @media #{$media-to-sm} {
            text-align: center;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @media #{$media-xs} {
                font-size: 14px;
                line-height: 18px;
            }
        }

        @media #{$media-md-up} {
            padding-top: 0;
            padding-bottom: 0;
        }

        ul {
            @media #{$media-sm-to-md} {
                margin-top: -10px;
            }
        }

        li {
            line-height: 200%;

            @media #{$media-to-sm} {
                font-size: 14px;
                line-height: 300%;
            }
        }

        a {
            display: block;
            font-size: 14px;

            &:hover {
                text-decoration: underline;
                color: $dark-green;
            }
        }
    }

    &__connect {
        padding-top: 35px;
        padding-bottom: 20px;

        @media #{$media-sm-up} {
            padding-top: 0;
            padding-bottom: 0;
        }

        &-newsletter {
            p {
                + p {
                    margin-top: 10px;
                }
            }

            #footer-newsletter-form-message {
                display: none;
                margin-top: 15px;
                margin-bottom: 15px;
                padding: 15px 25px;
                background-color: $sherpa-blue;
                border-radius: 10px;
                color: #fff;
            }

            &-header {
                margin-bottom: 15px;
            }

            .form-group {
                &._email {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;

                    input {
                        width: auto;
                        flex-grow: 1;
                        border-radius: 10px 0 0 10px;
                        font-style: normal;

                        @media #{$media-sm-to-md} {
                            width: 120px;
                            padding-left: 8px;
                            padding-right: 8px;
                            font-size: 14px;
                        }
                    }

                    button {
                        min-width: 100px;
                        padding-left: 15px;
                        padding-right: 15px;
                        border-radius: 0 10px 10px 0;
                    }
                }

                &._agreement {
                    input {
                        display: inline-block;
                        width: auto;
                        height: auto;
                        margin-top: 15px;
                        margin-right: 10px;
                    }

                    em {
                        color: $red;
                    }

                    .agreement-expand-text {
                        display: inline-block;
                        padding: 14px 0px;
                        text-decoration: underline;

                        @media #{$media-sm-to-md} {
                            padding: 5px 0;
                        }
                    }

                    .agreement-description {
                        margin-bottom: 14px;
                    }
                }
            }
        }

        &-social {
            margin-top: 6px;

            @media #{$media-sm-up} {
                margin-top: 15px;
            }

            &-facebook {
                a {
                    display: inline-block;
                    height: 43px;
                }

                img {
                    display: inline-block;
                    margin-right: 20px;
                    max-height: 43px;
                }
            }
        }
    }

    &__partners {
        margin-top: 35px;
        margin-bottom: 25px;

        @media #{$media-sm-to-md} {
            margin-top: 50px;
            margin-bottom: 40px;
        }

        @media #{$media-lg} {
            margin-bottom: 35px;
        }

        .content-slider {
            margin-bottom: 0;
        }

        .container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;

            &::before,
            &::after {
                display: none;
            }
        }

        #{$footer}-partners__slider-wrapper {
            width: 90%;
            max-width: 1560px;
        }

        .glider-slide {
            display: flex;
            justify-content: center;
            min-width: unset;
        }

        p {
            white-space: nowrap;
        }

        li {
            display: inline-block;
            margin-top: 20px;
            margin-right: 20px;

            @media #{$media-lg} {
                margin-top: 0;
                padding-top: 10px;
                padding-bottom: 10px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        img {
            width: auto;
            height: 26px;
        }
    }

    &__scroll-top {
        display: block;
        background-color: $blue-stone;
        border: none;
        margin-top: 40px;
        width: 100%;
        height: 60px;
        text-align: center;
        border-radius: 0;
        text-transform: none;

        &--text {
            line-height: 40px;
            font-size: 16px;
            font-weight: 600;
            color: $sherpa-blue;
        }

        &:hover {
            .footer__scroll-top--text {
                color: $brand-primary-text;
            }
            .icon-arrow-indicator {
                filter: $svg-white;
            }
        }

        .icon-arrow-indicator {
            transform: scale(1, -1);
            margin-left: 10px;
            filter: $svg-main-green;
        }
    }

    &__copyrights {
        background-color: #efefef;
        padding-top: 10px;
        padding-bottom: 10px;
        color: #555;

        p {
            margin: 0;
            text-align: center;
        }
    }
}

/* Hide some footer parts in checkout process */

.checkout-cart-index,
.checkout-onepage-login,
.checkout-onepage-angular {
    .footer {
        padding-top: 0;
        border-top-width: 0;

        &__mega-links-wrapper,
        &__main,
        &__newsletter,
        &__article,
        &__usp,
        &__contact,
        &__scroll-top,
        &__partners {
            display: none;
        }
    }
}

.cms-home,
.cms-index-index {
    .footer {
        .footer__trustmate {
            display: block !important;
        }
    }
}

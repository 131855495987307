 /* #OPTION BUTTONS
    -------------------------------------------------------------- */
    
.header-main {
    .header-options {
        height: 100%;
        display: flex;
        margin-left: auto;
        margin-right: 18px;
        justify-content: space-between;
        width: auto;

        @media #{$media-sm} {
           margin-right: 0;
        }

        @media #{$media-xs} {
            margin-right: 11px;
            align-items: center;
        }

        &__minicart,
        &__account,
        &__map {
            width: 53px;
            height: 53px;
            margin-left: 15px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media #{$media-xs} {
                min-height: 40px;
                width: 40px;
                height: 40px;
                margin-left: 10px;
            }
        }

        &__menu {
            @media #{$media-to-md} {
               margin-left: 21px;

               svg path {
                    fill: $sherpa-blue;
                }
            }
        }

        &__sticky-map {
            min-height: unset;
            min-width: unset;
            padding: 0;
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;

            &:hover,
            &:focus,
            &:active {
                background-color: transparent;
                color: $sherpa-blue;
            }

            .label {
                opacity: 1;
                text-align: left;
                font-size: 12px;
                max-width: 105px;
                margin-left: 10px;
                line-height: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                @media #{$media-md} {
                    font-size: 11px;
                    max-width: 80px;
                }

                @media #{$media-to-sm} {
                    display: none;
                    opacity: 0;
                }

                &:hover {
                    color: $sherpa-blue;
                }
            }
        }

        .suggester-is-focus & {
            @media #{$media-md-up} {
                z-index: -1;
                position: relative;
            }
        }

        .option {
            display: inline-block;
            vertical-align: middle;

            @media #{$media-to-sm} {
                font-size: 14px;
            }
            /* all buttons except minicart */
            & {
                margin-right: 15px;
            }

            /* minicart */
            &.header-main__minicart {
                display: inline-block;
                margin-right: 0;

                i {
                    color: inherit;

                    @media #{$media-to-sm} {
                        font-size: 30px;
                    }
                }
            }

            .ico {
                font-size: 44px;

                @media #{$media-xs} {
                    font-size: 23px;
                }

                & + .btn-nav {
                    display: none;
                }
            }

            &.is-toggle {
                .ico {
                    display: none;

                    & + span {
                        width: 23px;
                        height: 23px;
                        margin-left: 1px;
                        display: inline-block;

                        span {
                            background: palette(gray);
                        }
                    }
                }
            }

            .label {
                display: inline-block;
                margin-left: 8px;
                //font-weight: $font-weight-bold;
                text-align: left;
                color: palette(blue);

                @include n-theme() {
                    color: theme(color-primary);
                }

                @media #{$media-xs} {
                    display: none;
                }
            }

            &--menu {
                @media #{$media-md} {
                    display: inline-block;
                }
            }

            &--content {
                @media #{$media-lg} {
                    margin-right: 40px;
                }

                @media #{$media-md} {
                    margin-right: 20px;
                }

                span {
                    display: inline-block;
                    vertical-align: middle;
                }

                .label {
                    padding-left: 8px;
                    margin: 0;
                }
            }
        }

        .minicart {
            display: flex;
            align-items: center;
           

            @media #{$media-xs} {
                padding-left: 0;
                justify-content: center;
            }
        }

        // .header-open-menu {
        //     @media #{$media-to-sm} {
        //         svg path {
        //             fill: $sherpa-blue;
        //         }
        //     }
        // }

        .dropdown-menu {
            i {
                font-size: 18px;
                text-align: left;
            }

            .dropdown__title,
            ul li {
                display: flex;
            }
        }
    }
}
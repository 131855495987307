/* FULL
-------------------------------------------------------------- */
.section-full {
    width: 100%;
    margin: 80px 0 65px 0;

    &:last-of-type {
        margin-bottom: 0;
    }

    &--gray {
        margin: 0;
        padding-top: 60px;
        padding-bottom: 65px;
        background-color: palette(gray, lighter2);
    }
}

/* HEADER STICKY
-------------------------------------------------------------- */
.header-sticky {
    $b: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: $zindex-navbar-fixed;
    opacity: 1;
    box-shadow: 0 0 20px rgba(25, 25, 25, 0.1);
    border-bottom: 1px solid #e9e9e9;

    /* prevent flickering on chrome */
    -webkit-backface-visibility: hidden;
    //-webkit-transform: translateZ(0) scale(1.0, 1.0);

    transform: translate3d(0, -100%, 0);
    @include n-transition(all, 0.3s, ease-out);

    @media #{$media-xs} {
        box-shadow: none;
        border-bottom: 1px solid transparent;
    }

    &:focus {
        outline: 0;
    }

    &.is-sticky {
        opacity: 1;
        transform: translateZ(1px) translate3d(0, 0, 0);

        @media #{$media-xs} {
            -webkit-transform: translate3d(0,0,0);
        }
    }

    .modal-open &,
    .ngdialog-open & {
        .header-sticky__top {
            @media #{$media-md-up} {
                padding-right: 17px;
            }
        }
    }

    /* #TOP BAR
    -------------------------------------------------------------- */
    &__top {
        padding: 8px 0;
        background: #fff;

        > .container {
            align-items: center;

            .minicart {
                font-size: 15px;

                &__counter {
                    i {
                        font-size: 30px;
                    }
                }
            }
        }

        // mobile and tablet version of sticky
        @media #{$media-to-sm} {
            display: block;
            padding: 8px 0 2px;

            > .container {
                justify-content: space-between;
                align-items: center;
                padding: 0;

                .header-sticky__options {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 0;

                    > *:last-child {
                        flex: 0;
                        margin-left: 20px;
                    }
                }
            }
        }
    }

    /* #OPTIONS
    -------------------------------------------------------------- */
    &__options {
        .suggester-is-focus & {
            position: relative;
            z-index: -1;
        }
    }

    .header-options {
        &__map {
            border-radius: 50%;
        }
    }

    &__btn {
        &.search,
        &.chat {
            display: none;
            color: palette(blue);

            @include n-theme() {
                color: theme(color-primary);
            }

            i {
                font-size: 28px;

                @media #{$media-xs} {
                    font-size: 24px;
                }
            }

            @media #{$media-to-sm} {
                display: inline-block;
            }
        }

        &.search {
            &.is-toggle {
                i {
                    display: none;

                    & + .btn-nav {
                        display: inline-block;
                    }
                }
            }

            i {
                & + .btn-nav {
                    display: none;
                }
            }
        }

        &.menu {
            button {
                &:hover,
                &:active,
                &:focus {
                    @include n-theme() {
                        background: none;
                    }
                }
            }

            .btn-nav {
                @media #{$media-md-up} {
                    margin-right: 15px;
                }

                & + span {
                    font-size: 15px;
                    text-transform: none;
                    display: inline-block;
                    transform: translateY(-9px);
                }
            }
        }

        .btn-nav {
            @media #{$media-xs} {
                span {
                    width: 28px;
                    transform-origin: 28px;

                    & + span {
                        margin-top: 7px;
                    }
                }
            }
        }
    }

    &__search {
        @media #{$media-xs} {
            width: 100%;
            padding-left: 10px;
        }

        button {
            @media #{$media-xs} {
                min-width: 0;
            }
        }
    }

    /* apply styles for suggester */
    .suggester {
        @media #{$media-sm-up} {
            display: flex;
            justify-content: space-between;
        }


        &.is-focus {
            #{$b}__search {
                @media #{$media-sm} {
                    width: 500px;
                }
            }
        }

        #{$b}__search {
            top: -20px;

            @media #{$media-md} {
               left: 30px;
            }

            @media #{$media-sm} {
                top: 10px;
             }

            @media #{$media-xs} {
               top: 0;
            }
        }
    }

    .logo {
        position: static;
        display: inline-block;
        white-space: nowrap;
        vertical-align: middle;

        @media #{$media-xs} {
           padding-left: 15px;
        }

        img {
            display: block;

            @media #{$media-md-up} {
                max-width: initial;
            }
        }
    }

    .minicart {
        a {
            padding: 0;
        }

        &__counter {
            i {
                @media #{$media-xs} {
                    font-size: 30px;
                }
            }
        }
    }

    .backdrop {
        z-index: 1;
    }
}

/* HIDE STICKY HEADER ON LOGIN AND CREATE ACCOUNT MOBILE
-------------------------------------------------------------- */
.customer-account-login,
.customer-account-create,
.checkout-onepage-login {
    .header-sticky.is-sticky {
        @media #{$media-to-sm} {
            display: none;
        }
    }
}


.customer-account-login,
.customer-account-create,
.checkout-onepage-login,
.checkout-cart-index {
    .js-open-menu {
        display: none;
    }
}

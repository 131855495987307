.page-links {
    $b: &;
    width: 100%;
    background-color: $body-bg;
    height: 64px;
    overflow: hidden;

    @media #{$media-xs} {
        height: 50px;
    }

    #{$b}__wrapper {
        display: flex;
        height: 100%;
        padding-bottom: 2px;

        @media #{$media-xs} {
           padding-left: 0;
           padding-right: 0;
        }
    }

    &__item {
        font-size: 16px;
        font-weight: $font-weight-bold;
        padding-bottom: 6px;
        position: relative;
        min-width: 200px;
        max-width: 320px;
        height: 100%;
        color: $blue-dianne;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        z-index: 1;

        &:hover {
            color: $btn-default-hover;
        }

        @media #{$media-xs} {
            font-size: 10px;
            font-weight: $font-weight-light;
            min-width: 120px;
        }

        @media #{$screen} and (max-width: 360px) {
            min-width: 100px;
        }
       
        &:before {
            content: '';
            background:  $body-bg;
            width: 100%;
            height: 47px;
            position: absolute;
            display: block;
            z-index: -1;
            bottom: -4px;
            border: 1px solid $body-bg;

            @media #{$media-xs} {
                width: 100%;
                height: 36px;
            }
        }

        &--products {
            color: $sherpa-blue;

            @media #{$media-xs} {
                font-weight: $font-weight-xbold;
            }

            &:hover {
                color: $btn-default-hover;
            }

            &:before {
                background:  $gray-light
            }
        }
    }
}

.cms-content {
    .page-links {
        &__item {
            &--products {
                color: $blue-dianne;
    
                &:before {
                    background:  $body-bg;
                }
            }

            &--blog {
                color: $sherpa-blue;

                @media #{$media-xs} {
                    font-weight: $font-weight-xbold;
                }

                &:hover {
                    color: $btn-default-hover;
                }
        
                &:before {
                    background:  $gray-light;
                }
            }
        }
    }
}
$megamenuNavHeight: 62px;
$megamenuDesktopHeight: 650px;

.megamenu {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 1px;
        background: $sherpa-blue;
    }

    &__close {
        display: none;
    }

    &-product {
        position: relative;
        float: left;
        width: 244px;
        padding: 0px 20px;
        padding-top: 31px;

        &__title {
            text-align: center;
            color: $sherpa-blue;
            font-weight: 600;
            font-size: 18px;
            padding-bottom: 15px;
        }
    }

    &-banners {
        &__large {
            display: none;
            height: 135px;
            width: 770px;
            margin: 0 auto;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 12px;
            box-shadow: 0px 2px 12px 0px rgba(183, 189, 196, 0.5);
        }

        &__small {
            display: block;
            height: 180px;
            max-width: 180px;
            margin: 0 auto;
            border-radius: 12px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;

            @media screen and (max-width: 659px) {
                height: 128px;
                width: 128px;
            }

            @media screen and (max-width: 500px) {
                margin: 0;
            }

            &:after {
                content: attr(data-text);
                width: 100%;
                position: absolute;
                left: 0px;
                bottom: -30px;
                text-align: center;
                text-decoration: none;
                color: $mirage;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;

                @media screen and (max-width: 500px) {
                    bottom: -59px;
                    height: 59px;
                }
            }
        }

        &__box {
            float: left;
            width: 33.333333%;
            padding: 15px;

            @media screen and (max-width: 500px) {
                width: 100%;
                padding-bottom: 60px;
                padding-left: 0px;
            }
        }

        &__boxes {
            padding-top: 15px;

            &:after {
                content: '';
                display: block;
                height: 0;
                clear: both;
                visibility: hidden;
            }
        }
    }

    &-sub__head,
    &__close {
        height: 60px;
        padding: 20px;
        padding-top: 15px;
        padding-right: 20px;
        background: #fff;
        pointer-events: auto;

        i {
            display: block;
            position: relative;
            width: 44px;
            height: 44px;
            border-radius: 40px;
            border: 1px solid #d2d2d2;
            background: #fefefe;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
            float: left;
            cursor: pointer;
            margin-right: 15px;
            margin-top: -8px;

            &:before {
                content: '';
                display: block;
                width: 10px;
                height: 10px;
                border-color: #000;
                border-style: solid;
                border-width: 1px 0 0 1px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -5px;
                margin-left: -3px;
                transform: rotate(-45deg);
            }
        }

        a,
        span {
            color: $sherpa-blue;
            font-size: 18px;
            font-weight: 600;
            padding-top: 1px;
            float: left;

            @media screen and (max-width: 600px) {
                float: none;
                display: block;
            }
        }

        a:last-child {
            font-size: 16px;
            float: right;
            position: relative;
            padding-right: 25px;
            margin-top: 3px;

            @media screen and (max-width: 600px) {
                float: right;
                margin-top: 1px;
            }

            &:before,
            &:after {
                content: '';
                display: block;
                height: 7px;
                width: 7px;
                border-color: $sherpa-blue;
                border-style: solid;
                border-width: 0 2px 2px 0;
                position: absolute;
                top: 7px;
                right: 1px;
                transform: translateY(30%) rotate(-45deg);
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }

            &:after {
                right: 7px;
            }
        }
    }
    .product-shop__wishlist {
        display: none !important;
    }
}

.category-special-menu__head {
    @extend .megamenu-sub__head;
    background: none;
    padding-top: 0px !important;

    a:last-child {
        &:before,
        &:after {
            content: none;
        }
    }
}

@media screen and (max-width: 1199px) {
    .js-is-mm-open {
        overflow: hidden;

        .megamenu-container {
            display: block;
        }
    }

    .megamenu-container {
        position: fixed;
        z-index: 99999;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: scroll;
        background: #fff;
        display: none;

        &::-webkit-scrollbar {
            display: none;
            height: 0 !important;
        }
    }

    .megamenu {
        padding-bottom: 20px;

        &-product {
            max-width: 241px;
            margin: 0 auto;
            float: none;
            padding: 0 15px;
            padding-top: 20px;
        }

        &-banners {
            padding-top: 20px;
            padding-bottom: 20px;

            &__large {
                height: auto;
                width: 100%;
                padding-top: 38.2353%; // ratio z 340x130px
                background-position: center center;
            }
        }

        &__close {
            display: block;
            padding-top: 23px;
            padding-left: 0px;
        }

        &__list {
            padding-top: 20px;
        }

        &__item {
            &--act {
                > .megamenu-sub {
                    display: block;
                }
            }
        }

        &-hs--active {
            > .megamenu-sub {
                display: block;
            }
        }

        &-sub-list li a.megamenu__item--act {
            + .megamenu-sub {
                display: block;
            }
        }

        &__item,
        &-sub-list li > a {
            display: block;
            color: $mirage;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            padding-top: 14px;
            padding-bottom: 14px;
            cursor: pointer;
            position: relative;

            &--special {
                display: block;
                color: $red-btn;
            }
        }

        &__item,
        &-sub-list li.megamenu-hs > a {
            &:before {
                content: '';
                height: 10px;
                width: 10px;
                border-color: #000;
                border-style: solid;
                border-width: 0 2px 2px 0;
                position: absolute;
                top: 50%;
                margin-top: -8px;
                right: 0;
                transform: translateY(30%) rotate(-45deg);
            }
        }
    }

    .megamenu-sub {
        display: none;
        position: fixed;
        z-index: 99999;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: scroll;
        background: #fff;
        padding-left: 15px;
        padding-right: 15px;

        @media (min-width: $screen-sm-min) {
            width: 750px;
            left: 50%;
            margin-left: -375px;
        }

        @media (min-width: $screen-md-min) {
            width: 970px;
            left: 50%;
            margin-left: -485px;
        }

        &::-webkit-scrollbar {
            display: none;
            height: 0 !important;
        }

        &__head,
        .category-special-menu__head {
            display: block;
            padding-top: 23px;
            padding-left: 0px;
            padding-right: 0px;

            a {
                margin-top: 3px;
            }
        }

        &__nav > ul,
        &-list {
            padding-top: 20px;
            padding-bottom: 12px;
            border-bottom: 1px solid $gray-lighter;

            li {
                display: block;
            }

            &__title {
                color: #2ea48d;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                padding-bottom: 8px;

                &--blue {
                    color: #3d6cb2;
                }
            }
        }

        &__content {
            .megamenu-sub-list {
                li {
                    a {
                        color: $mirage;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        padding-top: 8px;
                        padding-bottom: 8px;

                        &.megamenu-sub-list__more {
                            color: $sherpa-blue;

                            i {
                                position: relative;
                                display: inline-block;
                                font-style: normal;
                                font-weight: 500;
                                line-height: 1;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;

                                &:before,
                                &:after {
                                    content: '';
                                    display: block;
                                    height: 6px;
                                    width: 6px;
                                    border-color: $sherpa-blue;
                                    border-style: solid;
                                    border-width: 0 1px 1px 0;
                                    position: absolute;
                                    top: -10px;
                                    left: 8px;
                                    transform: translateY(30%) rotate(-45deg);
                                }

                                &:after {
                                    left: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .category-special-menu {
        display: none;
        padding-top: 23px !important;
    }
}

@media screen and (min-width: 1200px) {
    .megamenu-container {
        position: relative;
        z-index: 10;
        background: white;

        &:before {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            right: 0;
            bottom: 0;
            height: 1px;
            background: $sherpa-blue;
        }

        &:after {
            content: '';
            width: 100%;
            background-color: #fbf9f9;
            position: absolute;
            z-index: -1;
            opacity: 0;
            visibility: hidden;
            top: 0px;
            @include n-transition(opacity, 0.3s);
            backface-visibility: hidden;
            transform: translateZ(0) scale(1, 1);
        }

        .dropdown-is-open & {
            &:after {
                opacity: 1;
                visibility: visible;
                height: 100vh;
            }
        }
    }

    .category-special-menu {
        .dropdown-is-open & {
            position: relative;
            z-index: -2;
        }
    }

    .megamenu {
        &__list {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: $megamenuNavHeight;
            padding-top: 5px;
            padding-left: 0;
            margin: 0;
        }

        &__item {
            display: flex;
            align-items: center;
            color: $blue-dianne;
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            padding: 0 10px;
            padding-bottom: 5px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;

            &:hover {
                color: $sherpa-blue;
                background: #fff;
                border: 1px solid $sherpa-blue;
                border-bottom: none;

                .megamenu-sub,
                .megamenu-sub:hover {
                    display: block;
                }
            }

            &--special {
                display: none;
            }
        }
    }

    .megamenu-sub {
        display: none;
        position: absolute;
        width: 1136px;
        left: 0;
        top: 0;
        z-index: 101;
        border-radius: 0 0 8px 8px;
        max-height: $megamenuDesktopHeight;
        height: $megamenuDesktopHeight;
        color: #000;
        cursor: auto;
        letter-spacing: 0px;
        overflow: hidden;
        pointer-events: none;
        border: 1px solid $sherpa-blue;
        border-top: none;

        &--first {
            top: $megamenuNavHeight;
            background: #fff;

            > .megamenu-sub__head {
                i {
                    display: none;
                }
            }
        }

        .megamenu-sub {
            display: none !important;
        }

        &-list {
            &__title {
                color: #3d6cb2;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                padding: 5px 0px;
                padding-bottom: 7px;

                &--blue {
                    color: #3397a5;
                    a {
                        &.megamenu-sub-list__more {
                            color: #3397a5 !important;
                            font-weight: 600;
                        }
                    }
                }
            }

            li {
                margin: 12px 0px;
                display: block;

                &.megamenu-hs {
                    > a {
                        &:before {
                            content: '';
                            height: 10px;
                            width: 10px;
                            border-color: #000;
                            border-style: solid;
                            border-width: 0 2px 2px 0;
                            position: absolute;
                            top: 50%;
                            margin-top: -8px;
                            right: -14px;
                            transform: translateY(30%) rotate(-45deg);
                        }
                    }
                }

                > a {
                    color: #000;
                    font-size: 16px;
                    font-weight: 500;
                    text-decoration: none;
                    line-height: 22px;
                    display: block;
                    padding: 5px 0px;
                    position: relative;
                    max-width: 250px;
                }

                a.megamenu-sub-list__more {
                    color: #3d6cb2;
                    font-weight: 600;
                    padding: 0;

                    i {
                        position: relative;
                        display: inline-block;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;

                        &:before,
                        &:after {
                            content: '';
                            display: block;
                            height: 7px;
                            width: 7px;
                            border-color: $sherpa-blue;
                            border-style: solid;
                            border-width: 0 2px 2px 0;
                            position: absolute;
                            top: -12px;
                            left: 18px;
                            transform: translateY(30%) rotate(-45deg);
                        }

                        &:after {
                            left: 24px;
                        }
                    }
                }

                &:first-child {
                    margin-top: 0px;
                    padding-top: 0px;
                }

                &:last-child {
                    margin-bottom: 0px;
                }

                &.megamenu-hs--active {
                    > .megamenu-sub {
                        display: block !important;
                    }
                }
            }
        }

        &__nav {
            width: 295px;
            height: 560px;
            padding: 0 20px;
            padding-right: 0px;
            background: #fff;
            overflow-x: hidden;
            overflow-y: auto;
            pointer-events: auto;

            @-moz-document url-prefix() {
                scrollbar-width: thin;
                scrollbar-color: $gray-lighter $gray-lighter2;
            }

            &::-webkit-scrollbar {
                scrollbar-width: thin;
                scrollbar-color: $sherpa-blue $gray-lighter2;
                height: 0px;
                width: 2px;
            }

            &::-webkit-scrollbar-thumb {
                scrollbar-width: thin;
                scrollbar-color: $sherpa-blue $gray-lighter2;
                background-color: $sherpa-blue;
            }

            &::-webkit-scrollbar-track {
                scrollbar-width: thin;
                scrollbar-color: $sherpa-blue $gray-lighter2;
                background: $gray-lighter2;
            }

            &::-webkit-scrollbar-thumb:hover {
                scrollbar-width: thin;
                scrollbar-color: $sherpa-blue $gray-lighter2;
                background: $astral;
            }

            li {
                a {
                    font-weight: 600;
                }
            }
        }

        &__content {
            position: absolute;
            top: 60px;
            left: 295px;
            right: 0px;
            bottom: 0px;
            background: #fff;
            pointer-events: auto;
            padding-left: 50px;
            padding-right: 20px;

            li {
                > a {
                    font-weight: 500;
                }
            }

            > div {
                padding-bottom: 53px;

                &:after {
                    content: '';
                    display: block;
                    height: 0;
                    clear: both;
                    visibility: hidden;
                }
            }

            .megamenu-sub-list {
                width: 100%;
                max-width: 260px;
                float: left;

                &:first-child {
                    padding-right: 15px;
                }
            }
        }
    }

    li[data-rootid] {
        > .megamenu__content {
            display: none;
        }
    }
}

/* =WRAPPER MAIN
-------------------------------------------------------------- */
html,
body,
.wrapper-main {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.wrapper-main {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100%;

    footer {
        margin-top: auto;
    }
}

/* =CONTENT MAIN
-------------------------------------------------------------- */
.content-main {
    padding-bottom: 40px;

    .container {
    }
}

body:not(.catalog-category-view) iframe {
    max-width: 100%;
}

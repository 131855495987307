.bs-prototype-override {
    display: block !important;
}

.hidden {
    display: none !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
}

.visible-mobile {
    @media #{$media-md-up} {
        display: none !important;
    }

    @media #{$media-to-sm} {
        display: block !important;
    }
}

.hidden-mobile {
    @media #{$media-md-up} {
        display: block !important;
    }

    @media #{$media-to-sm} {
        display: none !important;
    }
}

.nobr {
    white-space: nowrap !important;
}

.wrap {
    white-space: normal !important;
}

.small {
    font-size: $font-size-small !important;
}

.a-left,
.align-left {
    text-align: left !important;
}

.a-center,
.align-center {
    text-align: center !important;
}

.a-right,
.align-right {
    text-align: right !important;
}

.a-justify,
.align-justify {
    text-align: justify;
}

.v-top {
    vertical-align: top;
}

.v-middle {
    vertical-align: middle;
}

.n-center {
    @include n-center();
}

.f-left,
.left {
    float: left !important;
}

.f-right,
.right {
    float: right !important;
}

.f-none {
    float: none !important;
}

.f-fix {
    float: left;
    width: 100%;
}

.no-display {
    display: none !important;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.no-bg {
    background: none !important;
}

.no-radius {
    border-radius: 0 !important;
}

.no-transition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.invisible {
    opacity: 0 !important;
}

.invisible-out {
    position: absolute;
    text-indent: -99999px;
    left: -9999px;
    opacity: 0;
}

.visible {
    opacity: 1 !important;
}

.clearer {
    @include clearfix();
}

.mt-50 {
    margin-top: 50px;
}

.mb-20 {
    margin-bottom: 20px;
}

.text-elipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-pack: end;
    -webkit-box-orient: vertical;
    overflow: hidden;
    &--3 {
        -webkit-line-clamp: 3;
    }

    &--4 {
        -webkit-line-clamp: 4;
    }
}

.hidden-scroll {
    @media #{$media-xs} {
        scrollbar-width: none;
    }

    &::-webkit-scrollbar {
        @media #{$media-xs} {
            width: 0;
            display: none;
        }
    }
}

.link-arrow {
    position: relative;

    &:after {
        content: '';
        border-color: #000;
        border-style: solid;
        border-width: 0 2px 2px 0;
        right: 5px;
        bottom: 20px;
        display: block;
        height: 11px;
        position: absolute;
        transform: translateY(30%) rotate(-45deg);
        width: 11px;
    }
}

.add-to-cart-button-wrapper {
    width: 100%;
}
//
// Cool util for rwd/breakopoints debug, do not remove.
//

// body {
//     &::before {
//         content: '';
//         position: fixed;
//         inset: 0 0 100% 0;
//         border-bottom: 5px solid red;
//         z-index: 9999;

//         @media #{$media-xs-up} {
//             border-color: yellow;
//         }

//         @media #{$media-sm-up} {
//             border-color: pink;
//         }

//         @media #{$media-md} {
//             border-color: green;
//         }

//         @media #{$media-lg} {
//             border-color: blue;
//         }
//     }
// }

/* BUTTONS RESET
-------------------------------------------------------------- */
@mixin button-reset() {
    @include n-button-reset();
}

/* BUTTONS
-------------------------------------------------------------- */
.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    padding: 10px 10px;
    min-height: $btn-default-height;
    min-width: $btn-default-width;
    font-size: 16px;
    text-transform: none;
    font-weight: $btn-font-weight;
    color: $btn-primary-color;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    background-color: transparent;
    border: 0.5px solid $btn-default-border;
    border-radius: $button-radius;
    white-space: nowrap;
    @include user-select(none);
    @include n-transition();

    @include n-theme() {
        color: theme(button-color);
        border-color: theme(button-border);
    }

    &:focus,
    &:active,
    &:active:focus {
        box-shadow: none;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: $btn-default-hover;
        color: white;

        @include n-theme() {
            background: $btn-default-hover;
            border-color: $btn-default-hover;
        }
    }

    &[disabled],
    &[disabled]:focus,
    &[disabled]:hover,
    &[disabled]:active {
        background: #F7F7F8 !important;
        border: 1px solid #F7F7F8 !important;
        pointer-events: none !important;
        cursor: not-allowed !important;
        color: #A0A1A9 !important;
    }

    &:focus {
        outline: none;
    }

    /* icon + label */
    .icon,
    [data-icon] {
        font-size: 22px;

        & + span {
            margin-left: 8px;
        }
    }

    span {
        & + .icon,
        & + [data-icon] {
            margin-left: 8px;
        }
    }
}

/* apply settings for all buttons */
button {
    @extend .btn;
}

/* BUTTON VARIANTS
-------------------------------------------------------------- */
.btn {
    /* reset styles */
    &--clear {
        @include button-reset();
    }

    /* wide */
    &--wide {
        min-width: 195px;
    }

    &--small {
        min-width: 0;
        min-height: $btn-height-small;
    }

    /* large height */
    &--large {
        min-height: $btn-height-large;
    }

    /* block - full width */
    &--block {
        display: block;
        min-width: 0;
        width: 100%;
    }

    &--flex-center {
        display: inline-flex;
        justify-content: center;
        align-items: center;

        img,
        svg {
            max-height: 30px;
            margin-right: 10px;
        }
    }

    &--title {
        @media #{$media-to-sm} {
            margin-bottom: 15px;
        }
    }

    /* inverted colors */
    &--bg {
        color: #fff;

        @include n-hover(blue, (background-color, border-color));
        @include n-theme() {
            background-color: theme(color-primary);
            border-color: theme(color-primary);
            color: #fff;
        }
    }

    &--bright {
        border: 2px solid $persian-green;
        font-weight: 700;
        color: $gray-dark;
        text-transform: none;

        &:hover,
        &:active {
            border-color: #fff;
            color: #fff;
            background: $persian-green;
        }
    }

    &--red {
        background-color: $shiraz;
        border-radius: 30px;
        font-size: 16px;

        @include n-theme() {
            color: #fff;
            border-color: $shiraz;
        }
    }

    /* no border radius */
    &--rect {
        border-radius: 0;
    }

    /* button with spinner */
    &--loader {
        position: relative;

        &:disabled {
            .loader {
                display: block;
            }
        }

        .loader {
            display: none;
            @include n-abs($height: 30px, $width: 30px);
            left: 10px;
            border-width: 3px;
        }
    }

    /* big version on mobile */
    &--mobile {
        @media #{$media-xs} {
            width: 100%;
            display: block;
            padding-top: 18px;
            padding-bottom: 18px;
            white-space: normal;
        }
    }

    &--mobile-no-border {
        @extend .btn--mobile;
        @media #{$media-xs} {
            border-color: transparent;
        }
    }

    &--order {
        border-radius: 100px;
        border-color: $sherpa-blue !important;
        background: $sherpa-blue !important;
        color: white !important;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-transform: none;

        @media #{$media-xs} {
            font-size: 16px;
            min-height: auto;
        }

        &:hover {
            border-color: $dark-green !important;
            background: $dark-green !important;
        }
    }
}

/* BUTTON LINK
-------------------------------------------------------------- */
.btn-link {
    @include button-reset();
    //@include n-hover(#fff, color);

    color: $sherpa-blue;

    &:hover,
    &:focus {
        color: $sherpa-blue;
    }


    min-height: 50px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;

    &:hover,
    &:focus,
    &:active,
    &:visited {
        @include n-theme() {
            background: none;
        }
    }


    @media #{$media-xs} {
        font-size: 18px;
    }

    i {
        margin-left: 5px;
        font-size: 10px;
    }

    &--arrow {
        position: relative;
        padding-right: 13px;

        &-left {
            position: relative;
            padding-right: 0;
            padding-left: 13px;
        }
    }

    &--check {
        color: $green;
    }

    &--underline {
        text-decoration: underline;
        color: black;
        text-transform: initial;
        text-transform: unset;

        &:hover {
            text-decoration: underline;
        }
    }
}

.btn-inline {
    @include button-reset();
    text-transform: none;
    font-size: 16px;
    color: $blue;

    @include n-theme() {
        color: theme(color-primary);
    }

    &:hover,
    &:active,
    &:focus {
        text-decoration: underline;

        @include n-theme() {
            color: theme(color-primary);
        }
    }
}

/* BUTTON CIRCLE
-------------------------------------------------------------- */
.btn-circle {
    @include button-reset();
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid palette(blue);
    background: transparent;
    text-align: center;
    vertical-align: middle;
    color: palette(blue);
    @include n-transition();

    @include n-theme() {
        border-color: theme(color-primary);
    }

    &:hover {
        background: palette(blue);
        color: #fff;

        @include n-theme() {
            background-color: theme(background-primary);
        }
    }

    i {
        @include n-center(absolute);
        left: 0;
        right: 0;
        font-size: 9px;
    }
}

/* BUTTON CLOSE
-------------------------------------------------------------- */
.btn-close {
    @include n-circle(55px);
    @include n-hover(blue);
    @include n-theme() {
        background-color: theme(color-primary);
        color: #fff;

        &:hover,
        &:active,
        &:focus {
            background-color: $dark-green;
        }
    }

    &,
    &:hover,
    &:active,
    &:focus {
        color: #fff;
    }
}

/* BURGER MENU BUTTON
-------------------------------------------------------------- */
.btn-nav {
    span {
        display: block;
        height: 3px;
        width: 32px;
        border-radius: 3px;
        background: palette(blue);
        @include transition(transform 0.2s);
        @include transform-origin(33px);

        @include n-theme() {
            background: theme(background-primary);
        }

        & + span {
            margin-top: 9px;
        }

        &:nth-child(2) {
            @include transition(opacity 0.2s);
        }
    }

    &.is-toggle,
    .is-toggle &,
    &.active {
        span {
            &:first-child {
                transform: rotate(-45deg);
            }

            &:last-child {
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
            }
        }
    }
}

/* BUTTON GO TO CONTENT / SCREEN READERS HELPER
-------------------------------------------------------------- */
.btn-skip {
    position: absolute;
    top: 150px;
    right: 0;
    text-indent: -9999px;
    clip: rect(1px, 1px, 1px, 1px);

    &:focus {
        text-indent: 0;
        clip: auto;
        padding: 10px;
        background: $btn-default-bg;
        z-index: 999999;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.cookiefirst-root button,
.cookiefirst-root .btn {
    min-height: 0;
    min-width: 0;
}

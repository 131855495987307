/* AUTO SEARCH SUGGESTER
-------------------------------------------------------------- */
.suggester {
    $b: &;

    //@include n-transition(all);

    /* APPLY STYLES ON FOCUSED SEARCHBOX
    -------------------------------------------------------------- */
    &.is-focus {
        @media #{$media-sm-up} {
            z-index: $zindex-navbar-fixed + 2;
        }

        .suggester {
            &__form {
                z-index: 10;
                box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);

                @media #{$media-md-up} {
                    width: 620px;
                }

                input {
                    border-color: #fff;
                }
            }

            &__dialog {
                padding: 12px 10px 42px;

                @media #{$media-xs} {
                    padding: 4px 10px 30px;
                }

                &-info {
                    font-size: 14px;
                    margin-bottom: 0;
                    margin-top: 0;
                    display: none;
                }
                &--no-results {
                    padding: 12px 12px 14px;

                    .dialog__info {
                        display: block;
                    }
                }

                &.phrases-visible {
                    .all-results-link {
                        display: none;
                    }

                    .see-more {
                        display: flex;
                    }
                }
            }
            @media #{$media-xs} {
                &__form {
                    background: #fff;
                    padding: 12px 10px;
                    height: 100%;
                    position: fixed;
                    z-index: 11032;
                    overflow-y: auto;

                    button[type='submit'] {
                        border-bottom-right-radius: 12px;
                    }
                    .input-top-search {
                        border-bottom-left-radius: 24px;
                        border-top-left-radius: 24px;
                        border: 1px solid #969696;
                    }
                }
                &__dialog {
                    padding: 12px 0 42px;

                    @media #{$media-xs} {
                        padding: 4px 0 30px;
                    }
                }
            }
        }

        .backdrop {
            position: relative;
            z-index: 0;
        }

        .header-search__toggle {
            display: none;
        }
    }

    /* APPLY STYLES ON SEARCHBOX WHEN RESULTS ARE READY
    -------------------------------------------------------------- */
    &.has-results {
        .suggester {
            &__form {
                input {
                    border-bottom-left-radius: 0;
                    border-color: #fff;
                }

                button {
                    border-bottom-right-radius: 0;
                }
            }
        }
    }

    /* SPINNER STYLES
    -------------------------------------------------------------- */
    .loader {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 26px;
        height: 26px;
        margin: auto;
        right: 127px;
        z-index: 999;
        border-color: palette(gray, lighter3);
        border-left-color: palette(blue);

        @media #{$media-md} {
            right: 55px;
        }

        @media #{$media-sm} {
            right: 130px;
        }

        @media #{$media-xs} {
            right: 52px;
        }

        border-width: 3px;
        z-index: 999;
        border-color: palette(gray, lighter3);
        border-left-color: $sherpa-blue;
    }

    /* SUGGESTER FORM
    -------------------------------------------------------------- */
    &__form {
        @media #{$media-sm-up} {
            border-radius: 24px;
            background: #fff;
            @include n-transition(width, 0.25s);
        }

        @media #{$media-xs} {
            border-top: 1px solid rgba(183, 189, 196, 0.08);
            border-bottom: 1px solid rgba(183, 189, 196, 0.08);
            padding: 10px 0 10px 10px;
        }

        .input-top-search {
            border: 1px solid $gray-darker4;
            border-right-color: $red-btn;

            @media #{$media-xs} {
              font-size: 12px;
              border-right: none;
            }

            &:focus {
                box-shadow: none !important;
                border-width: 1px;
            }
        }

        input {
            height: 41px;
            border-radius: 24px 0px 0px 24px;
            padding-left: 28px;

            @media #{$media-xs} {
                height: 38px;
            }
        }
    }

    #{$b}__submit {
        background-color: $red-btn;
        border-color: $red-btn;
        width: 120px;
        min-height: 41px;
        height: 41px;
        border-radius: 0px 24px 24px 0px;
        text-transform: none;
        font-size: 16px;
        line-height: 1;

        @media #{$media-md} {
            min-width: 45px;
            width: 45px;
            height: 38px;
            border-radius: 0px 12px 12px 0px;
        }

        @media #{$media-xs} {
            min-width: 45px;
            width: 45px;
            height: 38px;
            min-height: 38px;
            border-radius: 0px 12px 12px 0px;
        }

        &:hover,
        &:focus {
            @include n-theme() {
                background-color: $red-btn;
                border-color: $red-btn;
            }
        }

        .icon {
            margin-left: -17px;

            @media #{$media-md} {
                margin-left: -2px;
            }

            @media #{$media-xs} {
                margin-left: 0;
            }
        }

        span {
            margin-top: -1px;

            @media #{$media-md} {
               display: none;
            }

            @media #{$media-xs} {
                display: none;
             }
        }
    }

    .header-search {

        @media #{$media-xs} {
            min-height: 8px;
        }

        &__wrapper {
            @media #{$media-xs} {
               margin-right: 10px;
            }

            &.is-hidden {
                .input-group {
                    @media #{$media-xs} {
                        display: none;
                    }
                }

                .header-search__toggle {
                    @media #{$media-xs} {
                        width: 60px;
                        height: 40px;
                        bottom: -25px;
                    }

                    .icon {
                        @media #{$media-xs} {
                            transform: rotate(180deg);
                            margin-top: 1px;
                        }
                    }
                }
            }
        }

        &__toggle {
            display: none;
        }
    }

    /* DIALOG WITH OPTIONS
    -------------------------------------------------------------- */
    &__dialog {
        display: none;
        padding: 25px;
        border-top: 1px solid palette(gray, lighter3);
        white-space: normal;

        @media #{$media-xs} {
            margin-top: 12px;
            border-top: 0;
        }

        .btn {
            max-width: 100%;
            min-width: 100px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 13px;
            padding: 10px 5px;
        }
    }

    /* SUGGESTS
    -------------------------------------------------------------- */
    .suggests {
        &__title {
            margin: 0;
            margin-bottom: 5px;
            font-size: 16px;
            margin-left: 15px;
            color: $sherpa-blue;
            font-weight: 700;

            @media #{$media-xs} {
                font-size: 15px;
                margin-bottom: 3px;
            }

        }

        &__container {
            margin-left: -10px;
            margin-right: -10px;
            .seperator {
                margin: 0 15px;
                height: 1px;
                display: flex;
                background: #efefef;
                margin-bottom: 13px;
                display: none;
            }

            .most-popular-list {
                display: none;
            }

            .list-links {
                margin-bottom: 10px;

                > li {
                    position: relative;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.16);

                    &:first-of-type {
                        border-top: 0;
                    }

                    &:nth-child(n+9) {
                       display: none;
                    }

                    &:nth-child(n+6) {
                        @media #{$media-to-sm} {
                            display: none;
                        }
                    }

                    @media only screen and (any-hover: hover) and (any-pointer: coarse) {
                        &:hover {
                            background-color: #e8edee;
                            cursor: pointer;
                        }
                    }


                    &:after {
                        content: '';
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 18'%3E%3Cpath fill='%23007F32' d='M29.88 0.929551C29.7279 0.563204 29.4367 0.272081 29.0703 0.119942C28.89 0.0431176 28.6964 0.00236884 28.5004 0H21.0026C20.6049 0 20.2235 0.157959 19.9423 0.439128C19.661 0.720297 19.503 1.10164 19.503 1.49928C19.503 1.89691 19.661 2.27825 19.9423 2.55942C20.2235 2.84059 20.6049 2.99855 21.0026 2.99855H24.8865L16.5039 11.3795L11.5703 6.43189C11.4309 6.29137 11.2651 6.17983 11.0823 6.10371C10.8996 6.0276 10.7036 5.98841 10.5057 5.98841C10.3077 5.98841 10.1117 6.0276 9.92895 6.10371C9.74622 6.17983 9.58036 6.29137 9.44096 6.43189L0.443569 15.4275C0.303017 15.5669 0.191458 15.7327 0.115327 15.9154C0.039196 16.0981 0 16.2941 0 16.492C0 16.69 0.039196 16.8859 0.115327 17.0686C0.191458 17.2513 0.303017 17.4171 0.443569 17.5565C0.582973 17.697 0.748827 17.8086 0.931563 17.8847C1.1143 17.9608 1.3103 18 1.50826 18C1.70622 18 1.90222 17.9608 2.08496 17.8847C2.26769 17.8086 2.43355 17.697 2.57295 17.5565L10.5057 9.61036L15.4392 14.558C15.5786 14.6985 15.7445 14.81 15.9272 14.8861C16.11 14.9623 16.306 15.0014 16.5039 15.0014C16.7019 15.0014 16.8979 14.9623 17.0806 14.8861C17.2633 14.81 17.4292 14.6985 17.5686 14.558L27.0009 5.11253V8.99565C27.0009 9.39329 27.1589 9.77463 27.4401 10.0558C27.7213 10.337 28.1027 10.4949 28.5004 10.4949C28.8981 10.4949 29.2796 10.337 29.5608 10.0558C29.842 9.77463 30 9.39329 30 8.99565V1.49928C29.9976 1.30335 29.9569 1.1098 29.88 0.929551Z'/%3E%3C/svg%3E%0A");
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        display: block;
                        width: 28px;
                        height: 15px;
                        position: absolute;
                        right: 15px;
                        top: 16px;

                        @media #{$media-xs} {
                            height: 13px;
                        }
                    }
                }

                a {
                    font-weight: 500;
                    font-size: 16px;
                    padding: 13px 15px;
                    color: $gray-dark;

                    @media #{$media-xs} {
                        font-size: 15px;
                        padding: 10px 15px;
                    }

                    b {
                        font-weight: $font-weight-xbold;
                    }
                }

                strong {
                    font-weight: $font-weight-bold;
                }
            }
        }

        &-promotions {
            display: none;
        }
    }

    /* PRODUCTS
    -------------------------------------------------------------- */

    .products {
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            margin-bottom: 5px;

            @media #{$media-xs} {
                margin-top: 12px;
            }
        }

        &__title {
            margin: 0;
            font-size: 18px;
            margin-left: 15px;
            font-weight: 600;

            &::first-letter {
                text-transform: uppercase;
            }

            @media #{$media-xs} {
                font-size: 14px;
            }
        }

        &__all-results {
            margin: 0;
            font-size: 12px;
            margin-right: 15px;

            .all-results-link {
                text-decoration: underline;
                @media #{$media-xs} {
                   font-weight: $font-weight-bold;
                }
            }

            .icon {
                position: relative;
                top: -2px;
            }

            .see-more {
                color: $sherpa-blue;
                text-transform: uppercase;
                font-weight: 600;
                display: none;
                align-items: center;
                font-size: 14px;

                svg {
                    fill: $sherpa-blue;
                    max-width: 11px;
                }
            }
        }

        &__container {
            margin-left: -10px;
            margin-right: -10px;
        }


        &__list {
            display: flex;
            align-items: stretch;
            margin-left: 10px;
            margin-right: 10px;
            display: flex;
            overflow: auto;
            padding-bottom: 25px;
            scrollbar-width: 8px;
            scrollbar-width: thin;
            scrollbar-color: $sherpa-blue #D9D9D9;

            &::-webkit-scrollbar {
                height: 8px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $sherpa-blue;
            }

            &::-webkit-scrollbar-track {
                background: #D9D9D9;
            }

            .product-simple__name {
                max-height: 45px;
                height: auto;
                font-size: 12px;
                line-height: 15px;
                margin-bottom: 9px;
                font-weight: 500;
            }

            @media #{$media-xs} {

                .suggester__item {
                    max-width: 42vw;
                    flex-basis: 42vw;
                    flex-shrink: 0;
                    flex-grow: 0;
                    width: 42vw;
                }

            }
        }
        @media #{$media-xs} {
            &__container-without-heading {
                overflow-x: auto;
                padding-bottom: 24px;
            }
        }

        &__item {
            padding-left: 10px;
            padding-right: 10px;

            > div {
                height: 100%;
                text-align: center;
            }
        }

        .regular-price {
            &.unavailable {
                color: $red;
                font-size: 12px;
            }
            &.refundable {
                font-size: 13px;
                font-weight: $font-weight-bold;
            }
        }
    }

    /* ITEM
    -------------------------------------------------------------- */
    &__item {
        padding-left: 10px;
        padding-right: 10px;
        min-width: 120px;

        .product-simple {
            height: 100%;
            text-align: left;

            &__link {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;
            }

            &__price {
                .price-box {
                    font-size: 14px;
                    &--rx {
                        line-height: 25px;
                    }
                }
            }

            &__img {
                display: grid;
                place-items: center;
                height: 100px;
            }

        }

        img {
            display: initial;
            max-height: 85px;
        }

        > div {
            height: 100%;
            text-align: left;
        }

        span {
            color: $blue-dianne;
            font-size: 14px;
        }

        a {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

    }
}

.header-sticky {
    .header-search {
        &__wrapper {
            @media #{$media-xs} {
                margin-right: 80px;

            }
        }

        &__toggle {
            @media #{$media-xs} {
                display: block;
                position: absolute;
                bottom: -22px;
                width: 60px;
                height: 40px;
                right: 0px;
            }

            span {
                font-size: 10px;
                color: $sherpa-blue;
                font-weight: $font-weight-bold;
            }

            .icon {
                @media #{$media-xs} {
                    margin-top: 2px;
                }
            }
        }
    }
}

/*** SEARCH HEADER FOR MOBILE ***/

.suggester {
    .icon-arrow-long  {
        display: none;
    }

    .header-search {
        position: relative;
    }

    .loader.hidden {
        &+ .search-reset {
            display: block;

            @media #{$media-lg-up} {
                right: 121px;
            }
        }
    }

    .search-reset {
        display: none;
        position: absolute;
        right: 61px;
        z-index: 9;
        border: 0;
        background: #fff;
        height: 35px;
        top: 3px;
        min-height: 20px;
        padding: 0 5px 0 5px;

        @media #{$media-md} {
            right: 50px;
        }

        @media #{$media-sm} {
            right: 121px;
        }

        @media #{$media-xs} {
            right: 48px;
            height: 31px;
        }

        &:hover, &:focus {
            color: $cyprus;
            border: 0;
            background: #fff;
        }
    }

    &.is-focus {
        @media #{$media-xs} {
            .form-search {
                width: 100%;
                margin-right: 0;
            }

            .header-search {
                display: flex;
                align-items: center;
                .icon-arrow-long {
                    color: $cyprus;
                    display: block;
                    margin-right: 11px;
                }
            }
        }
    }
}

//overwrite suggester input styles
.header-main {
    .suggester .form-search {
        .input-top-search {
            &::placeholder {
                color: $raven;
            }
        }
    }
}

.header-is-sticky.suggester-is-focus {
    overflow: hidden;


    .header-sticky__search {
        @media #{$media-xs} {
            min-height: 900px;
        }
    }
}

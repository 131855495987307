/* =SLIDERS GLOBAL SETTINGS
-------------------------------------------------------------- */
.slider {
    /* =SLIDES IN COLUMNS
    -------------------------------------------------------------- */
    &--row {
        // hide scrollbar
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        &::-webkit-scrollbar {
            /* WebKit */

            width: 0;
            height: 0;
        }

        .slick-list {
            margin-left: -15px;
            margin-right: -15px;
        }
    }
}

/* =HIDE PREV/NEXT
-------------------------------------------------------------- */
.product-slider {
    margin: 0 auto !important;
    padding: 0px 60px;

    &--article-related {
        padding: 0 0;
    }

    // will be enabled via js if needed
    &__btn--right,
    &__btn--left {
        visibility: hidden;

        &:hover {
            opacity: 1;
        }
    }

    button {
        &.disabled,
        &.disabled:hover {
            visibility: hidden;

            &:after {
                border-color: $sherpa-blue-light;
            }

            .icon {
                svg {
                    color: $sherpa-blue-light;

                    path {
                        stroke: $sherpa-blue-light;
                    }
                }
            }
        }
    }
}

.glider {
    scrollbar-width: none;
    padding-bottom: 2px;

    &::-webkit-scrollbar {
        display: none;
        height: 0 !important;
    }

    &-track {
        margin: 0 auto;
    }
}

// todo merge with new product page styles after release
.category-list {
    .product-slider {
        padding: 0 80px;

        @media #{$media-xs} {
            padding: 0 20px;
        }

        .product-list {
            &__item {
                padding-right: 10px;
                padding-left: 10px;
            }
        }

        .product-item {
            border-radius: 24px;
            padding: 5px 5px 20px;

            &__shop {
                padding: 0 5px;
            }

            &__desc {
                padding: 10px 10px 5px 7px;
            }

            &__img {
                border-bottom: none;
                min-height: 175px;
                display: flex;
                align-items: flex-end;
                padding-bottom: 0;

                img {
                    max-height: 100%;
                    max-width: 100%;
                    width: auto;
                }
            }

            &__btn {
                border-radius: 8px;

                .icon {
                    @media #{$media-xs} {
                        margin-left: 18px;
                        margin-right: 0;
                     }
                }
            }
            .input-group {
                @media #{$media-xs} {
                   display: table;
                }
            }

            .price {
                font-weight: $font-weight-bold;
                display: block;

                @media #{$media-xs} {
                   font-size: 22px;
                }
            }
        }

        .product-image {
            width: 128px;
            height: 128px;
            margin: 0 auto;
        }

        .tooltip-max-price-info {
            margin: 0;
            padding: 5px 3px 0;
        }

        &__btn {
            &--left,
            &--right {
                visibility: visible;
                opacity: 1;

                @media #{$media-xs} {
                    display: none;
                    visibility: hidden;
                }
            }
        }
    }
}

.c-products-slider {
    $b: &;
    padding: 40px 40px 40px;
    position: relative;
    background-color: rgba(242, 242, 242, 0.22);
    border-radius: 8px;
    margin-bottom: 20px;

    @media (min-width: 1351px) and (max-width: 1650px) {
        padding: 40px 130px;
    }

    @media #{$media-to-md} {
        padding: 20px 40px 20px;
    }

    @media #{$media-xs} {
        padding: 20px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-left: 15px;
        padding-right: 0;
    }

    &__btn {
        bottom: 0;
        position: absolute;
        top: 0;
        opacity: 1;

        @media #{$media-xs} {
           display: none;
        }

        &:focus,
        &:active {
            @include n-theme() {
                background: transparent;
            }
        }

        &--left {
            left: 10px;
            transform: rotate(180deg);

            @media #{$media-lg} {
                left: 25px;
            }

            @media #{$media-md} {
                left: 17px;
            }
        }

        &--right {
            right: 10px;

            @media #{$media-lg} {
                right: 15px;
            }

            @media #{$media-md} {
                right: 17px;
            }
        }

        svg {
            color: $sherpa-blue;
        }
    }

    .product-list {
        &__item {
            padding-right: 10px;
            padding-left: 10px;
        }
    }

    .product-item {
        border-radius: 8px;
        padding: 5px 5px 20px;

        @media #{$media-xs} {
           margin-right: 20px;
        }

        &__shop {
            padding: 0 5px;
        }

        &__desc {
            padding: 10px 10px 5px 7px;
        }

        &__img {
            border-bottom: none;
            min-height: 175px;
            display: flex;
            align-items: flex-end;
            padding-bottom: 0;

            img {
                max-height: 100%;
                max-width: 100%;
                width: auto;
            }
        }

        &__btn {
            border-radius: 8px;

            .icon {
                @media #{$media-xs} {
                    margin-left: 18px;
                    margin-right: 0;
                 }
            }
        }

        &__rx {
            max-width: 170px;
            margin: auto;
            top: 20px;

            strong {
                font-size: 17px;
            }
        }

        .price {
            font-weight: $font-weight-medium;
            display: block;

            @media #{$media-xs} {
               font-size: 22px;
            }
        }

        .product-label {
            height: auto;

            &--last_items {
                .product-label__text {
                    padding-right: 2px;
                }
            }
        }
    }

    .product-image {
        width: 128px;
        height: 128px;
        margin: 0 auto;
    }

    .tooltip-max-price-info {
        margin: 0;
        padding: 5px 3px 0;
    }

    &--green {
        background-color: $sherpa-blue;
        padding-top: 35px;
        padding-bottom: 50px;
        margin-top: 20px;

        @media #{$media-xs} {
           margin-left: 0;
        }

        #{$b}__title {
            color: #fff;
            text-align: center;
            font-size: 22px;
            margin-bottom: 25px;

            @media #{$media-xs} {
                font-size: 20px;
            }

            @media #{$media-xs} {
                text-align: left;
            }
        }

        //#{$b}__btn {
        //    svg {
        //        color: $sherpa-blue;
        //    }
        //}
    }

    &--bg {
        background-color: $sherpa-blue;
        border-radius: 12px;
        margin: 0 auto 30px;

        @media #{$media-md-up} {
            padding: 20px 125px;
        }

        @media #{$media-sm} {
            padding: 20px 60px;
        }

        @media #{$media-xs} {
           margin-left: 0;
           padding-bottom: 25px;
           border-top-right-radius: 0;
           border-bottom-right-radius: 0;
        }

        .product-item {
            border-radius: 24px;

            &--unavailable {
                height: 100%;
            }

            &__labels {
                left: 10px;
                top: 5px;
            }
        }

        #{$b}__title {
            color: #fff;
            text-align: center;
            font-size: 22px;
            margin-bottom: 29px;

            @media #{$media-xs} {
                font-size: 18px;
                text-align: left;
                max-width: 250px;
            }
        }

        #{$b}__list {
           display: flex;
        }

        #{$b}__item {
            @media #{$media-lg} {
                min-width: 25%;
            }
        }

        #{$b}__btn {
            bottom: auto;
            top: 51%;

            @media #{$media-sm} {
                display: none;
            }

            svg {
                color: #fff;
            }

            &--left {
                left: -40px;

                @media #{$media-sm} {
                    left: -20px;
                }
            }

            &--right {
                right: -40px;

                @media #{$media-sm} {
                    right: -20px;
                }
            }
        }

        #{$b}__wrapper{
            position: relative;
        }

        .glider-track {
            .c-products-slider__item {
                @media #{$media-lg} {
                    min-width: unset;
                }
            }
        }
    }

    &--gray {
        background-color: $gray-light;

        #{$b}__title {
            color: $blue-dianne;
        }

        #{$b}__btn {
            svg {
                color: $sherpa-blue;
            }
        }
    }

    &--white {
        background-color: #fff;

        #{$b}__title {
            color: $sherpa-blue;
        }

        #{$b}__btn {
            svg {
                color: $sherpa-blue;
            }
        }
    }
}

.special-offer-slider {
    padding-bottom: 40px;

    @media #{$media-xs} {
        padding-right: 0;
        padding-left: 0;
        margin-right: -15px;
        margin-left: -15px;
        padding-bottom: 30px;
    }
}

.slider-main {
    position: relative;
    width: 100%;
    margin: 20px 0 10px;

    &__slides {
        height: 100%;
        width: 100%;
        max-height: 420px;
        border-radius: 12px;
        box-shadow: 0px 2px 12px 4px rgba(183, 189, 196, 0.5036);
        overflow: hidden;
        display: flex;

        @media #{$media-md} {
            max-height: 400px;
        }

        @media #{$media-sm} {
            max-height: 266px;
        }

        @media screen and (max-width: 400px) {
            max-height: 238px;
        }

        @media screen and (max-width: 400px) {
            max-width: 328px;
        }
    }

    &__item {
        flex: 0 0 100%;
        scroll-snap-align: start;
    }

    .glider {
        .slider-main__item {
            flex: none;
        }
    }
}

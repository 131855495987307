/* ROUNDED CHECKBOX
-------------------------------------------------------------- */
$background-white: #fff;
$border-black: #000;

.round-checkbox {
    position: relative;
    height: 22px;
    width: 22px;
    .customer-active_label {
        background: #fff;
        border: 1px solid $sherpa-blue;
        border-radius: 4px;
        cursor: pointer;
        height: 20px;
        left: 0;
        position: absolute;
        bottom: 0;
        width: 20px;
        &:after {
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            right: 0;
            opacity: 0;
            position: absolute;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 65%;
            background-image: url('data:image/svg+xml,<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 1.19958L4.5 8.19958L1 5.19958" stroke="%23007F32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        }
        &-all {
            left: 0;
            top: 6px;
        }
    }
    input[type="checkbox"] {
        visibility: hidden;
        &:checked {
            + {
                label {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

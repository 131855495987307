.hellobar {
    width: 100%;
    background-color: #fff;
    padding: 25px;
    text-align: center;
    position: relative;
    transition: all .2s linear 0s;

    @media #{$media-xs} {
        padding: 30px 15px 20px;
    }

    &__text,
    p {
        font-size: 16px;
        font-weight: $font-weight-bold;
        margin: 0 0 10.5px;

        img {
            margin-left: 10px;
            display: inline-block;
        }
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;

        @media #{$media-xs} {
            flex-direction: column;
        }

        .hellobar__btn,
        button {
            background: $red-btn;
            border-radius: 12px;
            min-height: 28px;
            border-color: transparent;
            margin-left: 60px;
            color: #F4F5F5;
            font-size: 14px;
            padding: 0;
            text-transform: none;
            min-width: 140px;

            @media #{$media-xs} {
               margin-left: 0;
               margin-top: 12px;
               display: flex;
            }

            &:hover,
            &:focus {
                background: #E80003;
                border-color: transparent;
                outline: transparent;
            }
        }

        img {
            max-height: 40px;
            width: auto;
        }
    }

    .hellobar__close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        height: 20px;
        border: 1px solid $sherpa-blue;
        border-radius: 4px;
        background: #fff;
        color: $sherpa-blue;

        @include n-theme() {
            background: #fff;
        }

        &:hover {
            color: #fff;
            background: $sherpa-blue;

            svg {
                @include n-theme() {
                    fill: #fff;
                }
            }
        }

        .icon {
            display: flex;
            align-items: center;
        }

        svg {
            fill: $sherpa-blue;
            width: 12px;
            margin: 0 auto;
        }
    }

    &-flex {
        display: flex;
        align-items: center;

        @media #{$media-xs} {
           flex-direction: column;
        }
    }
}

.js-hellobar {
    display: block !important;
    height: 79px;

    @media #{$media-xs} {
        height: 142px;
    }

    &.is-hidden {
        display: none !important;
    }
}

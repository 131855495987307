/* DROPDOWN STLYES
-------------------------------------------------------------- */
// Bootstrap components
// ---
.dropdown {
    position: relative;

    &-toggle {
        &:focus {
            outline: 0;
        }
    }

    &-menu {
        position: absolute;
        display: none;
        top: 100%;
        left: 0;
        width: 100%;
        min-width: 290px;
        padding: 20px 20px 0 20px;
        text-align: left;
        z-index: $zindex-dropdown;
        background: #fff;
        border-top: 5px solid palette(blue);
        border-bottom: 5px solid palette(blue);
        box-shadow: 0 0 20px rgba(25, 25, 25, 0.1);

        @include n-theme() {
            border-color: theme(color-primary);
        }

        &,
        a {
            color: $text-color;
            width: 100%;
        }

        /* css triangle */
        &:before {
            content: '';
            display: block;
            position: absolute;
            right: 30px;
            top: -12px;
            bottom: 100%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 6.5px 7px 6.5px;
            border-color: transparent transparent palette(blue) transparent;

            @include n-theme() {
                border-bottom-color: theme(color-primary);
            }
        }

        .open & {
            display: block;
        }

        .dropdown__title-logout {
            padding: 0;
            width: 100%;

            a {
                width: calc(100% + 40px);
                margin: 0 -20px;
                padding: 20px 20px 20px 20px;
            }
        }
    }

    /* DROPDOWN MENU LINKS STYLES
    -------------------------------------------------------------- */
    &__title,
    &__links {
        position: relative;
        padding: 20px;
    }

    &__title {
        padding-left: 0;
        border: 1px solid palette(gray, lighter);
        border-width: 1px 0;
        font-weight: $font-weight-bold;

        &:first-of-type {
            padding-top: 0;
            border-top: 0;
        }

        &:last-of-type {
            padding-bottom: 0;
            border-bottom: 0;
        }

        .icon {
            margin: 0 5px 0 0;
            color: palette(blue);

            @include n-theme() {
                color: theme(color-primary);
            }

            &,
            & + span {
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    &__links {
        padding-left: 30px;

        li {
            &.active {
                a {
                    color: $blue;

                    @include n-theme() {
                        color: theme(color-primary);
                    }

                    //&:before {
                    //  @include icon;
                    //  @extend .icon-arrow-right;
                    //  @include n-abs($height: 14px);
                    //  left: -30px;
                    //  font-size: 14px;
                    //  font-weight: bold;
                    //}
                }
            }

            a {
                display: block;
                position: relative;
                line-height: 2.2;

                &:hover {
                    color: $blue;

                    @include n-theme() {
                        color: theme(color-primary);
                    }

                    //&:before {
                    //  @include icon;
                    //  @extend .icon-arrow-right;
                    //  @include n-abs($height: 14px);
                    //  left: -30px;
                    //  font-size: 14px;
                    //  font-weight: bold;
                    //}
                }
            }
        }
    }
}

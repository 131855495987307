/* NAVIGATION MAIN
-------------------------------------------------------------- */
#chat-widget-container {
    z-index: 10000 !important;
}

.header-main__navOld {
    background-color: $sherpa-blue;
    position: relative;
}

.header-main__navOld .nav-main {
    width: 100%;

    /* reset border radius on sticky header */
    @at-root .header-is-sticky & {
        &,
        & ul li:first-of-type,
        & ul li:last-of-type {
            border-radius: 0;
        }
    }

    li {
        /* hide submenu */
        .submenu {
            body:not(.cms-content) & {
                @media #{$media-to-md} {
                    display: none;
                }
            }

            .cms-content & {
                /* prevent displaying submenu by js */
                @media #{$media-sm-to-md} {
                    display: none;
                }
            }
        }
    }

    //arrows for bootstrap accoridon
    &__primary,
    &__link {
        a {
            .triangle {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 5px 4px 0 4px;
                border-color: #000 transparent transparent transparent;
            }

            &:not(.collapsed) {
                .triangle {
                    border-width: 0 4px 5px 4px;
                    border-color: transparent transparent #000 transparent;
                }
            }

            li:not(.nav-categories) & {
                .triangle {
                    margin: 0 0 0 auto;
                }
            }
        }
    }

    /* MAIN TOP LIST - FIRST LEVEL LINKS
    -------------------------------------------------------------- */
    &__primary {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 62px;
        align-items: center;

        &.collapse {
            > li {
                @media #{$media-xs} {
                    display: none;
                }
            }

            &.in {
                display: table;

                @media #{$media-xs} {
                    display: block;
                }

                > li {
                    @media #{$media-xs} {
                        display: block;
                    }
                }
            }
        }

        a {
            position: relative;

            .triangle {
                @media #{$media-xs} {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        /* first level - main items */
        > li {
            height: 100%;
            padding: 0 12.5px;

            &:first-child {
                min-width: 220px;
            }

            &:nth-last-child(-n+2) {
                @media #{$media-md} {
                    display: none;
                }
            }

            /* hover state */
            /* active state */
            &:hover,
            &.active {

                &.has-color {
                   > a {
                        background: transparent;
                   }
                }
            }

            /* links first main level */
            > a {
                color: #fff;
                display: flex;
                width: 100%;
                text-align: center;
                font-size: 18px;
                font-weight: $font-weight-medium;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
                height: 100%;

                @include n-transition();

                @media #{$media-to-sm} {
                    font-size: 14px;
                }

                @media #{$media-sm} {
                    display: flex;
                    height: auto;
                    width: 100%;
                    align-items: center;
                    white-space: normal;
                    justify-content: center;
                }

                @media #{$media-xs} {
                    text-align: left;
                    padding-left: 25px;
                    padding-right: 10px;
                    white-space: initial;
                }

                & > img {
                    margin-right: 15px;
                }
            }

            &.has-color {
                &:hover {
                    background-color: transparent;
                }
            }
        }

        /* specific styles for dropdown with columns*/
        .has-col {
            position: relative;

            /* one col dropdwon */
            &--one {
                .submenu {
                    width: 613px;
                }

                .secondary {
                    column-count: 1;
                    width: 48%;
                    padding-right: 15px;
                }
            }

            /* two cols dropdown */
            &--two {
                .submenu {
                    width: 920px;
                }

                .secondary {
                    column-count: 2;
                    width: 65%;
                }
            }

            /* adjust to right */
            &--right {
                position: static;

                .submenu {
                    left: auto;
                    right: 0;
                }
            }
        }
    }

    // link to content service
    .content {
        a {
            color: palette(blue);

            @include n-theme() {
                color: theme(color-primary);
            }
        }

        br {
            display: none;
        }

        span {
            display: inline-block;
            //vertical-align: middle;
        }

        .icon {
            vertical-align: top;
        }

        .label {
            padding-left: 5px;

            @media #{$media-xs} {
                padding-left: 0;
            }
        }
    }

    /* NAVIGATION MAIN - LINKS ON MOBILE
    -------------------------------------------------------------- */

    &__link {
        display: none;

        @media #{$media-xs} {
            display: block;
            border: 1px solid palette(gray, light-hover);
        }

        &:first-of-type {
            @media #{$media-xs} {
                margin-bottom: 1px;
            }

            a,
            span {
                color: $gray-dark;
            }

            a:not(.collapsed) {
                background-color: $gray-lighter5;
                margin-bottom: 0;
            }
        }

        a {
            .triangle {
                margin: 0 0 0 auto;
            }

            @media #{$media-xs} {
                font-weight: 600;
                padding: 17px 15px;
                display: block;
                dispaly: -webkit-flex;
                display: flex;
                align-items: center;
            }

            span:first-of-type {
                width: 35px;
            }

            i {
                color: $gray-dark;
                font-size: 20px;
            }
        }
    }

    /* NAVIGATION MAIN - SUBMENU
    -------------------------------------------------------------- */
    &__submenu {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        z-index: 9999;
        border-top: 1px solid #ebebeb;
        display: none;
        text-align: left;
        padding: 50px;
        overflow: hidden;

        a {
            @include n-theme() {
                color: theme(color-primary);
            }
            font-size: 15px;
        }

        @media #{$media-to-sm} {
            position: static;
            display: block;
            padding: 0;
            box-shadow: none;
        }

        &.collapse {
            li {
                @media #{$media-xs} {
                    display: none;
                }
            }

            &.in {
                li {
                    @media #{$media-xs} {
                        display: block;
                    }
                }
            }
        }
    }

    /* SUBMENU LIST - SECOND LEVEL
    -------------------------------------------------------------- */
    &__secondary {
        width: 100%;

        @media #{$media-md-up} {
            column-count: 4;
        }

        @media #{$media-sm} {
            column-count: 3;
        }

        > li {
            margin-bottom: 12px;
            text-transform: none;

            & > a {
                &:hover {
                    text-decoration: underline;
                }
            }

            @media #{$media-xs} {
                display: block;
                width: auto;
                background-color: $gray-lighter2;
                margin-bottom: 1px;
                font-weight: 600;
            }
        }

        .level1 {
            width: 92%;
            vertical-align: top;
            display: inline-block;

            @media #{$media-xs} {
                width: 100%;
            }

            a {
                @media #{$media-xs} {
                    display: block;
                    color: $gray-dark;
                    padding: 15px 10px 15px 40px;
                }

                &:first-letter {
                    text-transform: uppercase;
                }

                &:before {
                    display: none;
                }
            }

            .tab-nav__item {
                &:hover {
                    background: transparent;
                }
            }
        }

        .view-all {
            display: none;
        }
    }
}

body.is-mobile-menu-open {
    .header-overlay {
        -webkit-animation: mainmenuOpen 0.33s ease;
        -o-animation: mainmenuOpen 0.33s ease;
        animation: mainmenuOpen 0.33s ease;
    }
}

body.is-mobile-menu-closing {
    .header-overlay {
        -webkit-animation: mainmenuClosing 0.33s ease;
        -o-animation: mainmenuClosing 0.33s ease;
        animation: mainmenuClosing 0.33s ease;
    }
}

/* ALL CATEGORIES
-------------------------------------------------------------- */
.nav-categories {
    $b: &;
    overflow: hidden;

    .header-is-sticky & {
        border-left: 1px solid palette(gray, light-hover);
    }

    &:hover,
    &.active {
        > a {
            .triangle {
                border-width: 0 4px 5px 4px;
                border-color: transparent transparent #000 transparent;
            }
        }
    }

    .triangle {
        display: inline-block;
        margin: -2px 0 0 10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        border-color: #000 transparent transparent transparent;
        vertical-align: middle;
    }

    &__submenu {
        width: 100%;

        #{$b}__secondary {
            @media #{$media-xs} {
                column-count: 1;
            }
        }
    }

    &__label {
        margin-bottom: 20px;
        font-size: 16px;
        color: #7b7b7b;
    }

    &__main {
        position: relative;
        margin-bottom: 25px;

        @media #{$media-xs} {
            padding-bottom: 15px;
        }

        &:after {
            content: '';
            position: absolute;
            left: -20px;
            right: -30px;
            bottom: 0;
            height: 1px;
            background: #cdcdcd;
        }

        #{$b}__label {
            @media #{$media-sm-up} {
                display: inline-block;
            }
        }

        a {
            display: block;
            margin-bottom: 15px;

            @media #{$media-sm-up} {
                display: inline-block;
                margin: 0 25px;
            }
        }
    }

    &__dropdown {
        display: none;
        position: absolute;
        top: 38px;
        left: 15px;
        right: 15px;

        &:focus {
            outline: 0;
        }

        &.is-open {
            display: block;
        }

        #{$b}__submenu {
            position: static;
            display: block;
            box-shadow: 0px 1px 9px 0px rgba(0, 0, 0, 0.25);

            @media #{$media-xs} {
                padding: 30px 30px 10px 20px;
            }

            li {
                a {
                    color: $blue;

                    @media #{$media-xs} {
                        padding: 0;
                    }
                }

                @media #{$media-xs} {
                    background-color: rgba(0, 0, 0, 0);
                    font-weight: normal;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

/* NAVIGATION ASIDE
-------------------------------------------------------------- */
.nav-aside {
    li,
    dt,
    & > ul {
        a {
            position: relative;
            display: block;

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }

    dt {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            height: 1px;
            width: 100%;
            left: 0;
            background: #fff;

            @media #{$media-md-up} {
                top: -1px;
            }
        }

        a {
            padding: 13px 10px 13px 10px;
            background: palette(gray, light);
            font-weight: bold;
        }
    }

    li {
        a {
            padding: 15px 22px 15px 15px;
            border-bottom: 1px solid $sherpa-blue-light;

            &:hover,
            &:active,
            &:focus, {
                color: $white;
                background-color: $sherpa-blue;;

                &:before {
                    font-size: 14px;
                    font-weight: bold;
                }
                .icon-arrow-indicators {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(350deg) brightness(102%) contrast(102%);
                }
            }

            &:active {
                background: palette(blue);
                border-bottom-color: palette(blue);

                @include n-theme() {
                    background-color: theme(color-primary);
                    border-bottom-color: theme(color-primary);
                }

                color: #fff;

                &:before {
                    right: 15px;
                }
            }

            &:hover {
                @media #{$media-md-up} {
                    background: palette(blue);
                    border-bottom-color: palette(blue);

                    @include n-theme() {
                        background-color: theme(color-primary);
                        border-bottom-color: theme(color-primary);
                    }

                    color: #fff;

                    &:before {
                        right: 15px;
                    }
                }
            }

            &:focus {
                @media #{$media-to-sm} {
                    outline: 0;
                    &:before {
                        display: none;
                    }
                }

                @media #{$media-md-up} {
                    background: palette(blue);
                    border-bottom-color: palette(blue);

                    @include n-theme() {
                        background-color: theme(color-primary);
                        border-bottom-color: theme(color-primary);
                    }

                    color: #fff;

                    &:before {
                        right: 15px;
                    }
                }
            }

            &.current {
                color: palette(blue);

                @include n-theme() {
                    color: theme(color-primary);
                }

                @media #{$media-to-sm} {
                    @include n-theme() {
                        color: #fff;
                    }
                }

                &:before {
                    @media #{$media-to-sm} {
                        right: 15px;
                    }

                    @media #{$media-md-up} {
                        left: -15px;
                        color: palette(blue);

                        @include n-theme() {
                            color: theme(color-primary);
                        }
                    }
                }

                &:hover,
                &:active,
                &:focus {
                    color: #fff;

                    &:before {
                        left: auto;
                        color: #fff;
                    }
                }

                &:hover,
                &:focus {
                    @media #{$media-to-sm} {
                        &:before {
                            display: block;
                        }
                    }
                }

                &:active {
                    @media #{$media-xs} {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }

                @media #{$media-xs} {
                    padding-bottom: 16px;
                    padding-left: 24px;
                }

                @media #{$media-to-sm} {
                    background: palette(blue);
                    border-top: 1px solid palette(blue);
                    border-bottom-color: palette(blue);
                    color: #fff;
                    margin-top: -1px;

                    @include n-theme() {
                        background-color: theme(color-primary);
                        border-top-color: theme(color-primary);
                        border-bottom-color: theme(color-primary);
                    }
                }
            }
        }
    }

    ol {
        display: table;
        width: 100%;

        @media #{$media-to-sm} {
            column-count: 2;
            column-gap: 0;
            display: block;
        }

        @media #{$media-xs} {
            column-count: 1;
        }

        li {
            @media #{$media-to-sm} {
                width: 99%;
                display: inline-block;
            }

            @media #{$media-xs} {
                width: calc(100% - 48px);
            }

            a {
                &:hover {
                    &:before {
                        @media #{$media-to-sm} {
                            display: none;
                        }
                    }
                }
            }

            &:nth-child(-n + 2) {
                a {
                    @media #{$media-sm} {
                        border-top: 1px solid $gray-lighter;
                    }
                }
            }

            &:first-child {
                a {
                    @media #{$media-xs} {
                        border-top: 1px solid $gray-lighter;
                    }
                }
            }

            &:nth-child(odd) {
                @media #{$media-sm} {
                    margin-right: 4%;
                }
            }

            &:last-child {
                @media #{$media-to-sm} {
                    margin-bottom: 20px;
                }
            }
        }
    }

    @at-root .block-layered-nav .count {
        font-family: $font-family-secondary;
        font-weight: normal;
    }

    @at-root .block-layered-nav .block-title .count {
        margin-top: 3px;
        font-size: 17px;
        vertical-align: bottom;
    }
}

#menu-mobile-root {
    padding-top: 95px;

    .menu-mobile {

        ul.mobile-submenu {
            display: none;
        }

        a.mobile-submenu__link {
            width: 100%;
            padding: 15px 20px 15px 35px !important;
            display: block;
            border-bottom: 1px solid #b9c6c8;
        }

        li {
            font-size: 16px;
            line-height: 1.25em;

            &.was-clicked {
                & > ul.mobile-submenu {
                    display: block;
                    padding-left: 20px;
                }
            }

            &.menu-link:not(.mobile-submenu__meta) {
                &.has-submenu {
                    a > .arrow {
                        @include n-button-reset();
                        padding: 20px;
                        position: absolute;
                        right: 0;
                        top: 3px;
                        margin: 0;
                        &:hover, &:focus {
                            @include n-theme() {
                               background-color: transparent;
                            }
                        }
                        &::after {
                            content: '';
                            display: block;
                            transform: rotate(-45deg);
                            width: 10px;
                            height: 10px;
                            border: 1px solid #0e2e34;
                            border-top-width: 0;
                            border-left-width: 0;
                            margin-top: -2px;
                        }
                    }
                }
                a {
                    position: relative;
                    display: block;
                    padding: 15px 30px 15px 20px;
                    border-bottom: 1px solid #b9c6c8;

                    &:focus {
                        outline: none;
                    }
                }

                &.current, &.was-clicked {
                    &> a {
                        margin-left: 0;
                        margin-right: 0;
                        padding-left: 20px;
                        padding-right: 30px;
                        color: #fff;
                        background-color: $sherpa-blue;

                        & > .arrow::after {
                            border-color: #fff;
                        }

                        &::after {
                            right: 20px;
                            border-color: #fff;
                        }
                    }
                }
                &.was-clicked > a {
                    & > .arrow::after {
                        transform: rotate(45deg);
                    }
                }
            }
        }

        &-title {
            margin-top: 20px;
            margin-bottom: 20px;
            margin-left: 15px;
            margin-right: 20px;
            font-size: 22px;
            line-height: 1.1em;
            text-transform: uppercase;
            color: $sherpa-blue;
        }

        &--main {
            > li {
                > a {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
        }
    }
}

@keyframes mainmenuOpen {
    from {
        transform: translate3d(-100%, 0, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes mainmenuClosing {
    from {
        transform: translate3d(0, 0, 0);
    }
    to {
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes submenuOpen {
    from {
        opacity: 0;
        transform: translate3d(-50%, 0, 0);
    }
    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

@keyframes submenuClosing {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    to {
        opacity: 0;
        transform: translate3d(-50%, 0, 0);
    }
}

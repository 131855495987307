/* BREADCRUMBS LIST
-------------------------------------------------------------- */
.breadcrumbs {
    &__scrollarea {
        @media #{$media-to-md} {
            max-width: 100%;
            overflow-x: auto;
            display: grid;

            &::-webkit-scrollbar {
                display: none;
                height: 0 !important;
            }
        }
    }
    &__wrapper {
        min-height: 18px;
        display: grid;
        margin: 15px 0 38px 0;
        position: relative;

        @media #{$media-to-md} {
            margin-bottom: 15px;
            position: relative;
            &::after {
                content: '';
                display: block;
                width: 50px;
                height: calc(100% - 10px);
                position: absolute;
                right: 0;
                top: 0;
                box-shadow: inset -55px 0px 30px -21px #ffffff;
            }
        }

        @media #{$media-sm-up} {
            margin: 25px 0 25px 0;
        }
    }
}

.breadcrumbs {
    font-size: 14px;
    line-height: 18px;
    scrollbar-width: none;
    white-space: nowrap;

    .customer-account-login &,
    .checkout-onepage-login & {
        display: none !important; //reason: overwrite JS initialization of breacrumbs
    }

    @media #{$media-lg} {
        display: none; // initialize it with JS
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media #{$media-to-md} {
        margin-bottom: 10px;
        padding-right: 50px;
    }

    &::-webkit-scrollbar {
        display: none;
        // if browser doesn't support hiding scrollbar with display: none:
        width: 0;
        background: transparent;
    }

    li {
        display: inline-block;
        background-position: left 6px center;
        background-repeat: no-repeat;
        background-size: 6px 12px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 32'%3E%3Cpath fill='currentColor' d='M16 16.743c-.19 0-.381-.072-.526-.218L.218 1.27C-.073.979-.073.508.218.218s.762-.291 1.052 0l15.256 15.255c.291.291.291.762 0 1.052-.146.146-.336.218-.527.218zM.744 32c-.19 0-.381-.072-.526-.218-.291-.291-.291-.762 0-1.052l15.256-15.256c.291-.291.762-.291 1.052 0s.291.762 0 1.052L1.269 31.782c-.145.145-.335.218-.526.218z'/%3E%3C/svg%3E%0A");
        padding-left: 20px;

        &:first-child {
            padding-left: 0;
            background: none;
        }

        @media #{$media-lg} {
            display: inline;
        }
    }

    a {
        text-decoration: underline;
        //font-weight: 600;
        @include n-hover(blue, color);
        @include n-theme() {
            color: theme(color-secondary);
        }

        &:hover {
            @include n-theme() {
                color: theme(color-secondary);
            }
        }
    }

    strong {
        font-weight: normal;
        color: $breadcrumb-active-color;
    }
}

$special-menu-index: 21474836400;
.customer-account-login,
.checkout-onepage-login {
    .header-main {
        .category-special-menu {
            display: none !important;
        }
    }
}
.header-main {
    .category-special-menu {
        background: #FBF9F9;
        min-height: 62px;

        &__head {
            display: none;
        }

        @media #{$media-to-sm} {
            display: none;
            position: fixed;
            top: 0;
            height: 100vh;
            width: 100%;
            z-index: $special-menu-index;
            padding-top: 30px;

            &__head {
                display: block;
            }
        }

        .category-special-menu__wrapper {
            justify-content: center;
            align-items: center;

            @media #{$media-to-sm} {
                justify-content: flex-start;
                min-width: 100%;
                padding: 0 15px;
            }
        }

        &__list {
            display: flex;
            align-items: center;
            height: 62px;

            @media #{$media-md-up} {
                overflow: scroll;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                   display: none;
                }
            }


            @media #{$media-to-sm} {
               flex-direction: column;
               height: 100%;
               width: 100%;
            }
        }

        &__icon {
            margin-top: -3px;
            margin-right: 19px;
            height: 24px;
            width: 24px;
        }

        ul {
            margin: 0;
            padding: 0;

            li {
                padding: 0 14px;
                list-style: none;
                margin: 0;

                @media #{$media-to-sm} {
                   width: 100%;
                   padding: 0;
                }

                a {
                    color: $mirage;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    white-space: nowrap;

                    @media #{$media-md} {
                        font-size: 15px;
                    }

                    @media #{$media-to-sm} {
                        padding: 15px 0 9px;
                        border-bottom: 1px solid $porcelain;
                        width: 100%;
                    }

                    svg path {
                        fill: $sherpa-blue;
                    }
                }

                .icon {
                    margin-right: 12px;
                    max-width: 17px;
                }
            }
        }

        &.is-open {
            @media #{$media-to-sm} {
                display: flex;
                z-index: $special-menu-index;
                max-width: 400px;
            }

            #header-overlay-backdrop {
                @media #{$media-to-sm} {
                   display: none;
                }
            }
        }
    }
}

/* GRID SETTINGS
-------------------------------------------------------------- */
.grid {
    // custom grid spacing
    &--space-50 {
        overflow: hidden;

        > .row {
            @media #{$media-md-up} {
                margin-left: -25px;
                margin-right: -25px;
            }

            // custom cols settings
            > [class*='col-'] {
                @media #{$media-md-up} {
                    padding-left: 25px;
                    padding-right: 25px;
                }
            }
        }
    }
}

.header-main,
.header-sticky {
    .header-overlay {
        display: none; // it's triggered by clicking on a button (JS)
        position: fixed;
        overflow-y: scroll;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 30000000;
        background-color: white;

        @media #{$media-sm} {
            width: 380px;
            -webkit-box-shadow: 0 0 40px 20px rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 40px 20px rgba(0, 0, 0, 0.15);
        }

        .nav-main__link {
            display: none;
        }

        .header-overlay__title {
            position: fixed;
            z-index: 100;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            width: 100%;
            margin: 0;
            padding: 26px 20px 20px 15px;
            border-bottom: 1px solid;
            border-color: rgba(0, 0, 0, 0.1);
            justify-content: space-between;
            color: black;
            background-color: #fff;

            @media #{$media-to-sm} {
                height: 95px;
            }

            @media #{$media-sm} {
                width: 380px;
            }

            & > span,
            & > a {
                text-transform: uppercase;
                font-weight: 600;
                font-size: 18px;
            }

            & > a:not(.logo) {
                padding-left: 30px;
                display: flex;
                align-items: center;
                position: relative;

                &::after {
                    display: block;
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 50%;
                    transform: translateY(50%) rotate(-45deg);
                    border: 2px solid black;
                    border-bottom: 0;
                    border-right: 0;
                    height: 12px;
                    width: 12px;
                }
            }

            & > i {
                display: inline-block;
                width: 48px;
                height: 48px;
                text-align: center;
                padding: 8px 0;
                cursor: pointer;
                font-size: 30px;
            }

            img {
                max-width: 160px;
                width: 160px;

                @media #{$media-xs} {
                    max-width: 120px;
                    width: 120px;
                    margin: 0;
                }
            }
        }

        .header-overlay__list {
            @include n-theme() {
                border-bottom: 1px solid theme(color-primary);
            }
            display: block;
            height: 100%;

            li {
                display: block !important;
            }

            & > li {
                background: white;
                display: block !important; // first level is always shown
                margin: 0 !important;
                padding: 25px 20px !important;
                position: relative;

                & > a {
                    display: inline-block;
                    padding: 0;

                    & > img {
                        display: none;
                    }
                }

                &:not(:first-of-type) {
                    @include n-theme() {
                        border-top: 1px solid theme(color-primary);
                    }
                }

                &.nav-categories > a::after {
                    content: '';
                    display: block;
                    position: absolute;
                    bottom: 50%;
                    transform: translateY(30%) rotate(45deg);
                    right: 20px;
                    width: 16px;
                    height: 16px;
                    @include n-theme() {
                        border: 2px solid theme(color-primary);
                    }
                    border-top-width: 0 !important;
                    border-left-width: 0 !important;
                }

                &.selected > a::after {
                    transform: translateY(70%) rotate(45deg);
                    border-top-width: 2px !important;
                    border-left-width: 2px !important;
                    border-bottom-width: 0 !important;
                    border-right-width: 0 !important;
                    background-color: white;
                }

                &:hover a {
                    background: white;
                }
            }

            .nav-categories__label {
                display: none;
            }

            .secondary {
                padding-top: 20px;

                & > li {
                    font-weight: initial;
                    text-transform: initial;
                    background: white;

                    & > a {
                        padding: 5px 0;
                        border: none !important;
                        outline: none !important;
                        @include n-theme() {
                            color: theme(color-primary);
                        }
                    }
                }
            }

            .nav-categories__submenu {
                border: none;
            }
        }

        .btn {
            margin: 18px auto;
            max-width: 400px;
            display: flex;
        }

        .select-pharmacy-overlay-wrapper {
            margin: 18px auto;
            max-width: 400px;
            display: flex;
            flex-direction: column;
            border-radius: 12px;
            align-items: center;
            overflow: hidden;
            @include n-theme() {
                border: 1px solid theme(color-primary);
            }

            & > div {
                text-align: center;
                width: 100%;

                .btn {
                    width: 80%;
                    margin: 10px auto;
                    border-radius: 12px;
                }

                &.pharmacy-info > span {
                    display: inline-block;
                    margin-top: 10px;
                }
            }

            a {
                margin: 0;
                border-radius: 0;
            }
        }
    }

    .header-overlay-subcategory-content {
        padding: 20px;
        margin-bottom: 20px;

        .block-title {
            border: none;
            padding-top: 20px;
            pointer-events: none; // mobile and tablet dont have collapsing menu

            & > span {
                display: none;
            }
        }

        .nav-aside li a.current:hover,
        .nav-aside li a.current:active,
        .nav-aside li a.current:focus {
            @include n-theme() {
                color: theme(color-primary);
            }
        }

        .header-overlay-subcategory-title {
            font-size: 18px;
            font-weight: initial;
            color: black;
            pointer-events: none;
            padding-bottom: 10px;
            display: inline-block !important;
            border: none;
        }

        a {
            border: none !important; // overwrite styles from aside navigation markup
            background: none !important;
            padding: 10px 0 !important;
            font-weight: initial !important;
            @include n-theme() {
                color: theme(color-primary);
            }

            &:hover {
                text-decoration: underline;
                font-weight: initial;
            }
        }

        .block-content.collapse {
            display: block;
        }
    }
}

#header-overlay-backdrop {
    display: none;
    position: fixed;
    z-index: 4000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.1);
}

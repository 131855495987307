/* INPUT GROUPS
-------------------------------------------------------------- */
.input-group {
    position: relative; // For dropdowns
    display: table;
    border-collapse: separate; // prevent input groups from inheriting border styles from table cells when placed within a table

    // Undo padding and float of grid classes
    &[class*='col-'] {
        float: none;
        padding-left: 0;
        padding-right: 0;
    }

    .input-custom {
        // Ensure that the input is always above the *appended* addon button for
        // proper border colors.
        position: relative;
        z-index: 2;

        // IE9 fubars the placeholder attribute in text inputs and the arrows on
        // select elements in input groups. To fix it, we float the input. Details:
        // https://github.com/twbs/bootstrap/issues/11561#issuecomment-28936855
        float: left;

        width: 100%;
        margin-bottom: 0;

        &:focus {
            z-index: 3;
        }
    }
}

// Display as table-cell
// -------------------------
.input-group-addon,
.input-group-btn,
.input-group .input-custom {
    display: table-cell;

    &:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
}

// Addon and addon wrapper for buttons
.input-group-addon,
.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle; // Match the inputs
}

// Text input groups
// -------------------------
.input-group-addon {
    padding: $padding-base-vertical $padding-base-horizontal;
    font-size: $font-size-base;
    font-weight: normal;
    line-height: 1;
    color: $input-color;
    text-align: center;
    background-color: $input-group-addon-bg;
    border: 1px solid $input-group-addon-border-color;
    border-radius: $input-border-radius;

    // Nuke default margins from checkboxes and radios to vertically center within.
    input[type='radio'],
    input[type='checkbox'] {
        margin-top: 0;
    }
}

// Reset rounded corners
.input-group .input-custom:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-addon:first-child {
    border-right: 0;
}

.input-group .input-custom:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-addon:last-child {
    border-left: 0;
}

/* EQUAL HEIGHT ROW BY TABLE
-------------------------------------------------------------- */
.row-eq-height {
    display: table;
    width: 100%;
    table-layout: fixed;

    @media #{$media-to-sm} {
        display: block;
        width: auto;
    }

    > * {
        display: table-cell;
        float: none;
        vertical-align: top;
    }

    & > div {
        @media #{$media-to-sm} {
            display: block;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 32px;
        }
    }
}

/* EQUAL HEIGHT ROW BY FLEXBOX
-------------------------------------------------------------- */
.row-flex {
    display: flex;
    flex-wrap: wrap;

    > [class*='col-'] {
        display: flex;
        flex-direction: column;
    }
}

.slider-main__product {
    display: none;
}
